import { studyDELETE, studyPOST } from './api-calls';
import { fetchDataForWorkspace } from './workspace';
import { studyIdGet } from './api-calls';

export function deleteStudy(id, name, version, status, topData, workspaceData, setWorkspaceData) {
  if (window.confirm(`Are you sure you want to permanently delete study ${name} and all contained documents?`)) {
      const studies = workspaceData.studies.filter(curr => curr.id !== id);
      
      setWorkspaceData({
        ...workspaceData,
        studies: studies
      });
      
      studyDELETE(topData, id, version);
  
  }
}

export async function createStudy(details, topData, setTopData, studyData, setStudyData, loadData, setLoadData) {
  try {
    const response = await studyPOST(details, topData);
    if (response.status === 200) {
      const data = await response.json();
      // Handle successful response
      console.log("data", data);
      setLoadData({
        ...loadData,
        isLoading: true,
      });
      studyIdGet(topData, setTopData, studyData, setStudyData, loadData, setLoadData, data.id, data.version)

    } else {
      const errorData = await response.json();
      alert(errorData.message || 'Error creating new study.');
    }
    return response;
  } catch (error) {
    console.error('Error in createStudy:', error);
    alert('An error occurred while creating the study.');
  }
}