import './App.css';
import { useTopDataStore } from "./data/TopDataStoreProvider";
import { useDocumentDataStore, emptyDocument } from './data/DocumentDataStoreProvider';
import { useWorkspaceDataStore } from './data/WorkspaceDataStoreProvider';
import { Container, Row, Col } from 'react-bootstrap';
import NavSide from './components/NavSide';
import { useEffect } from 'react';

function OutletSwitch() {
  const { topData } = useTopDataStore();
  
  switch (topData.page) {    
      case "createDocOutlet":
      case "createDocTemplate":
      default:
        return (<TemplateList />);
    }
}

function TemplateList() {
  const { topData, setTopData } = useTopDataStore();
  const { documentData, setDocumentData } = useDocumentDataStore();
  const { workspaceData } = useWorkspaceDataStore();
  
  const gotoInput = (event, id, version) => {
    event.preventDefault();

    const [sectionData] = workspaceData.activeTemplates.reduce((prev, curr) => {
      if (curr.id === id) {
        return [curr.sections];
      }
      else {
        return prev;
      }
    }, []);

    setDocumentData({
      ...documentData,
      sections : sectionData, 
      template_id : id,
      template_version : version,
    });

    setTopData({
      ...topData,
      page : 'studyInformation',  // TO DO: Update this to navigate to documentOutlet
    });

  };
  
  return( 
    <Container>
      <Row>
        <h3>
          Select a base template
          <br/>
          <br/>
        </h3>
      </Row>
      <Row>
        { workspaceData.activeTemplates ? workspaceData.activeTemplates.map((t) => { 
          return (
            <div key={t.id} className="docListItem">
              <a href="#id" onClick={(e) => gotoInput(e, t.id, t.version)}>
                {t.name} <br/><span className="docListItemDetail">{t.description}</span>
              </a>
            </div>
          );
        }) : <div>No active templates available.</div> }
      </Row>
    </Container>
  );
}

export default function CreateDocOutlet() {
  const { setDocumentData } = useDocumentDataStore();
  
  useEffect(() => {
    // Clear document data to start as a new document
    setDocumentData(emptyDocument);
  }, [setDocumentData]); // Dependency array ensures this runs only on mount

  // Define items to appear in the page's side navigation
  const sideNavVars = [
    {
      title: "Select Template",
      page: "createDocTemplate"
    }, 
    /* {
      title: "Metadata",
      page: "createDocMetadata",
    },
    {
      title: "Team",
      page: "createDocTeam",
    },*/    
  ];
  
  // Page layout
  return(
    <Container className="mainPage">
      <Row>
        <h2 className="pageTitle">
          Create a New Document
        </h2>
      </Row>
      <Row> 
        <Col xs={2} className="sectionSidebar">
          <NavSide elements={sideNavVars} />
        </Col>
        <Col>
          <OutletSwitch />           
        </Col>
      </Row>
    </Container>
  );
}