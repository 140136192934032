import { Nav, Navbar } from 'react-bootstrap';
import { useTopDataStore } from '../data/TopDataStoreProvider';

function ListItem(props) {
  // Temporary use of TopDataStoreProvider for page info until we add router
  const { topData, setTopData } = useTopDataStore();

  const side_event = (event) => {
    event.preventDefault();
    try {
      setTopData({ 
        ...topData, 
        page: props?.page, 
      });
    } catch (error) {
      console.error("Error in creating side navigation link event (", props?.page, "): ", error);
    }
  };
 
  try{ 
    return (
      <Navbar.Text className="side nav-link" key={props.linkId} id={props.linkId} onClick={side_event}>
        <a href={"#side-nav-"+props?.page}>{props?.title}</a>
      </Navbar.Text>
    );
  } catch (error) {
      console.error("Error in creating side navigation link (", props?.page, "): ", error);
      return(<> </>);
  }
}

export default function NavSide(props) {
  
  // NavSide accepts an array or object of objects wth links for side navigation
  // Each object in the set must have a `title` and `page`.
  // Optionally, an object may have a `sub` property, which will define sub-level styling (to come).
  const navLinks = Object.keys(props.elements);

  if (navLinks.length) {
    return(
      <Nav className="pt-4 side d-block">      
        {
          navLinks.map((item) => {
            return(
              <ListItem 
                  key={"nav-side-" +item}
                  title={props.elements[item].title} 
                  page={props.elements[item].page}
                  linkId={"nav-side-"+item}
                  sub={props?.elements[item]?.sub} 
              />
            );
          })
        }
      </Nav>
    );
  }
  else{ return("Add links to build side navigation.");} 
}