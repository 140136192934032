import { callToBackend } from "./fetch";

export function deleteDoc(e, id, name, workspaceData, setWorkspaceData, topData) {
  e.preventDefault();
  if (window.confirm(`Permanently delete document ${name}?`)) {
    const existing = workspaceData.docs.reduce((prev, curr) => {
      if (curr.id !== id) {
        prev.push(curr);
      }
      return prev;
    }, []);
    
    setWorkspaceData({
      ...workspaceData,
      docs: existing,
    });
    
    const params = {
      email: topData.userEmail,
      documentId: id,
      Key: topData.AccessKeyId,
      Secret: topData.SecretAccessKey,
      nonce: topData.nonce,
      groupName: topData.groupName,
      userId: topData.userId,
    };
    const url = topData.endpoint + '/document';
    callToBackend(url, params, topData.token, {}, 'DELETE')
    .then((res) => {
      if (200 !== res.status) {
        res.json().then((data) => {
          if (data.message) {
            alert(data.message);
          } else {
            alert('Error deleting document.'); 
          }
        });
      }
    })
    .catch((err) => {
      console.error(err);
      alert('Error deleting document.');
    });
  }
}

export function cloneDoc(e, id, name, version, documentData, setDocumentData, workspaceData, setWorkspaceData, topData) {
  e.preventDefault();
  
  setDocumentData({
    ...documentData,
    cloning: id,
  });
  const url = topData.endpoint + '/document';
  const params = {
    email      : topData.userEmail,
    documentId : id,
    version    : version,
    cloneName  : `Clone of ${name}`,
    Key        : topData.AccessKeyId,
    Secret     : topData.SecretAccessKey,
    nonce      : topData.nonce,
    groupName  : topData.groupName,
    userId     : topData.userId,
  };
  callToBackend(url, params, topData.token, {}, 'POST')
  .then((res) => {
    if (200 === res.status) {
      res.json().then((data) => {
        data.documentData.id = data.id;
        setWorkspaceData({
          ...workspaceData,
          docs : [ ...workspaceData.docs, data.documentData ],
          companyDocuments : [ ...workspaceData.companyDocuments, data.documentData ],
        });
        setDocumentData({
          ...documentData,
          cloning : 0
        });
      });
    }
    else {
      res.json().then((data) => {
        if (data.message) {
          alert(data.message);
        }
        else {
          alert('Error cloning document.'); 
        }
      });
    }
  })
  .catch((err) => {
    console.error(err);
    alert('Error cloning document.');
  });
}
