import { callToBackend } from "./fetch";

export async function updateVariable(variableData, topData) {
    const url = topData.endpoint + '/variable';
    const params = {
        email: topData.userEmail,
        groupName: topData.groupName,
        userId: topData.userId,
        id: variableData.id || "",
        ...variableData
    };
    const response = await callToBackend(url, params, topData.token, {}, 'PUT');
    return response;
}

export async function deleteVarset(id, version, topData) {
  const url = topData.endpoint + '/variable';
  const params = {
      email: topData.userEmail,
      groupName: topData.groupName,
      userId: topData.userId,
      id: id,
      version: version,
  };
  const response = await callToBackend(url, params, topData.token, {}, 'DELETE');
  return response;
}