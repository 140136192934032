import React, { useEffect, useState } from 'react';
import { Container, Row, Dropdown } from 'react-bootstrap';

import CreateStudy from './components/CreateStudy.js'; 
import { Plus } from './components/Icons.js'; 

import { studyIdGet } from './functions/api-calls.js';
import { callToBackend } from './functions/fetch.js';
import { deleteStudy, createStudy } from './functions/studies.js';

import { useWorkspaceDataStore } from './data/WorkspaceDataStoreProvider.js';
import { useTopDataStore } from './data/TopDataStoreProvider.js';
import { useLoadDataStore } from './data/LoadDataStoreProvider.js';
import { useStudyDataStore } from './data/StudyDataStoreProvider.js';

function Sublist({ studies, title }) {
    const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
    const { topData, setTopData } = useTopDataStore(); 
    const { studyData, setStudyData } = useStudyDataStore();
    const { loadData, setLoadData } = useLoadDataStore();
    return (
        <Row>
            { /* <h3 className="subheading">{title}</h3> */ }
            <div className="d-flex flex-wrap gap-3 align-items-start">
                {studies.length === 0 ? (
                    <p>No {title.toLowerCase()}</p>
                ) : (
                    studies.map((study, index) => (
                        <div 
                            key={index} 
                            className="listBlockWrapper" 
                            style={{ width: '31%'}}
                        >
                            <h5>{study.name}</h5>
                            <p>{study.description}</p>
                            <p>Version: {study.version}</p>
                            {study.cloneStudy && <p>Clone of: {study.cloneStudy}</p>}
                            <div className="d-flex justify-content-end">
                                <Dropdown>
                                    <Dropdown.Toggle variant="link" className="button-link">
                                        Actions
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => 
                                                {setLoadData({
                                                    ...loadData,
                                                    isLoading: true
                                                });
                                            studyIdGet(topData, setTopData, studyData, setStudyData, loadData, setLoadData, study.id, study.version);
                                            }}
                                        >
                                            Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item 
                                            onClick={() => 
                                                deleteStudy(study.id, study.name, study.version, study.status, topData, workspaceData, setWorkspaceData)
                                            }
                                        >
                                            Hard delete
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleCreateNewVersion(study.id)}>
                                            Create new version
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </Row>
    );
}

function handleCreateNewVersion(studyId) {
    alert("Versioning is not yet available for study with ID: " + studyId);
}

export default function StudyList() {
    const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
    const { topData, setTopData } = useTopDataStore();
    const { studyData, setStudyData } = useStudyDataStore();
    const { loadData, setLoadData } = useLoadDataStore();
    const [showModal, setShowModal] = useState(false);
    const [newStudy, setNewStudy] = useState({
        name: '',
        description: '',
        cloneVersion: '',
        cloneId: ''
    });

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewStudy({
            ...newStudy,
            [name]: value
        });
    };

    const handleSave = (formState) => {
        const details = {
            name: formState.name,
            description: formState.description,
            cloneVersion: formState.cloneVersion,
            cloneId: formState.cloneId
        };

        createStudy(details, topData, setTopData, studyData, setStudyData, loadData, setLoadData)
            .then(response => {
                if (response.status === 200) {
                    handleClose();
                } else {
                    alert(`Error: ${response.statusText}`);
                }
            })
            .catch(error => {
                alert(`Error: ${error.message}`);
            });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = {
                    email: topData.userEmail,
                    userId: topData.userId,
                    groupName: topData.groupName,
                };
                const url = `${topData.endpoint}/study`;
                const res = await callToBackend(url, params, topData.Authorization, {}, 'GET');
                
                if (res.status === 401) {
                    const data = await res.json();
                    alert(data.message || "Error retrieving workspace studies.");
                } else if (res.status === 200) {
                    const data = await res.json();
                    setWorkspaceData({
                        ...workspaceData,
                        studies: data.studies
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []); // Run once on load

    return (
        <Container>
            <Row>
                <h2 className="pageTitle mt-4">Studies</h2>
            </Row>
            <Row>
                <div className="docListItem" id="newStudy"> {/* REVIEW CSS */}
                    <a href="#id" onClick={handleShow}>
                        <Plus />Create New Study
                    </a>
                </div>
            </Row>
            <Sublist studies={workspaceData.studies} title="Studies" />

            <CreateStudy 
                show={showModal} 
                handleClose={handleClose} 
                newStudy={newStudy} 
                handleChange={handleChange} 
                handleSave={handleSave} 
                existingStudies={workspaceData.studies}
            />
        </Container>
    );
}
