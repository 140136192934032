import { templateDELETE, templatePOST } from './api-calls';
import { templateIdGet } from './api-calls';

export function deleteTemplate(id, name, version, status, topData, workspaceData, setWorkspaceData) {
  if (window.confirm(`Are you sure you want to permanently delete template ${name} and all derived draft templates and documents?`)) {
      const activeTemplates = status === "draft" ? workspaceData.activeTemplates : workspaceData.activeTemplates.filter(curr => curr.id !== id);
      const draftTemplates = status !== "draft" ? workspaceData.draftTemplates : workspaceData.draftTemplates.filter(curr => curr.id !== id);
      
      setWorkspaceData({
        ...workspaceData,
        activeTemplates: activeTemplates,
        draftTemplates: draftTemplates,
      });
      
      templateDELETE(topData, id, version);
  
  }
}

export function openTemplate(event, id, version, draftId, name, description, templateData, setTemplateData, topData, setTopData, draft = false) {
  event.preventDefault();

  setTemplateData({
    ...templateData,
    footer: true,
    template_id: id,
    template_version: version,
    draft_id: draft ? draftId : "",
    dirty: 0,
    draftName: draft ? name : "",
    description: draft ? description : "",
    name: draft ? undefined : name,
    templateType: draft ? "draft" : "live",
  });

  setTopData({
    ...topData,
    loading: false,
    isSaving: false,
    loginWarning: "",
    page: "templateSetup",
  });
}

export async function createTemplate(details, topData, setTopData, templateData, setTemplateData) {
  try {
    const response = await templatePOST(details, topData);
    if (response.status === 200) {
      const data = await response.json();
      console.log(data);
      
      templateIdGet(topData, setTopData, templateData, setTemplateData, data.id, data.version)

    } else {
      const errorData = await response.json();
      alert(errorData.message || 'Error creating new template.');
    }
    return response;
  } catch (error) {
    console.error('Error in createTemplate:', error);
    alert('An error occurred while creating the template.');
  }
}