import { createContext, useState, useContext } from "react";

/*
    The Workspace Data Store holds data about the list of templates, documents, 
    and variables available to a user. Data about individually loaded templates, 
    documents, or variables are stored in DocumentDataStore, TemplateDataStore, 
    and WorkspaceDataStore.
*/
const WorkspaceDataStore = createContext();
export const useWorkspaceDataStore = () => useContext(WorkspaceDataStore);

export const refreshState = {

  varSets : [], 
  studies : [],
  documents : [],
  activeTemplates : [], 
  draftTemplates : [], 

};

export default function WorkspaceDataStoreProvider({children}) {
  const [workspaceData, setWorkspaceData] = useState(refreshState);
  
  return (
    <WorkspaceDataStore.Provider value={{ workspaceData, setWorkspaceData }}>
      {children}
    </WorkspaceDataStore.Provider>
  );
} 
