import '../styles/rebrand.css';
import { useDocumentDataStore } from '../data/DocumentDataStoreProvider';
import { useTopDataStore } from '../data/TopDataStoreProvider';
import React, { useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { callToBackend } from '../functions/fetch';
import { Editor } from '@tinymce/tinymce-react';
import { useWorkspaceDataStore } from '../data/WorkspaceDataStoreProvider';
import { useStudyDataStore } from '../data/StudyDataStoreProvider';
import { Plus, Up, Down } from '../components/Icons';

function DisplaySection({ section, onEdit, onCancel, onSave, isEditing, dataStore, updateOrderOnNestingChange, updateOrderManual, studyData }) {
    const [localHtml, setLocalHtml] = useState(section.html);
    const [localHeading, setLocalHeading] = useState(section.heading);
    const [localGuidance, setLocalGuidance] = useState(section.guidance);
    const { workspaceData } = useWorkspaceDataStore();
    const editorRef = React.useRef(null);
    const [isSaving, setIsSaving] = useState(false);
    const [showNestDropdown, setShowNestDropdown] = useState(false);
    const [isNesting, setIsNesting] = useState(false);
    const [localOrder] = useState(section.order);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isMoving, setIsMoving] = useState(false);

    const replacePlaceholders = (html) => {
        if (!studyData || !studyData.values) {
            console.log("studyData or studyData.values is undefined");
            return html;
        }
        console.log("studyData.values keys:", Object.keys(studyData.values));
        return html.replace(/>#([^<]+)/g, (match, p1) => {
            console.log("Found placeholder:", match);
            const transformedPlaceholder = p1.match(/\.(comma|bullet|\d+)$/) ? p1.replace(/\.(comma|bullet|\d+)$/, '') : p1;
            console.log("Transformed placeholder:", transformedPlaceholder);
            const value = studyData.values[transformedPlaceholder]?.[0];
            if (value) {
                console.log("Value found:", value);
                return `<span data-placeholder="${p1}">${value !== undefined ? value : match}</span>`;
            } else {
                return match;
            }
        });
    };

    const revertPlaceholders = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        div.querySelectorAll('span[data-placeholder]').forEach(span => {
            span.replaceWith(`>#${span.getAttribute('data-placeholder')}`);
        });
        return div.innerHTML;
    };

    const getParentLevel = () => {
        if (!section.parentId) return 0; 
        const parentSection = dataStore.sections.find(s => s.id === section.parentId);
        return parentSection ? parentSection.level : section.parentId;
    }

    const handleEditToggle = () => {
        if (isEditing) {
            onCancel();
        } else {
            onEdit();
        }
    };

    const handleSaveSection = async () => {
        setIsSaving(true);
        const originalHtml = revertPlaceholders(editorRef.current ? editorRef.current.getContent() : localHtml);
        const params = {
            sections: [{
                id: section.id,
                version: section.version,
                heading: localHeading,
                guidance: localGuidance,
                html: originalHtml,
                parentId: section.parentId,
                order: localOrder,
                level: section.parentId ? getParentLevel() + 1 : 1,
            }]
        };
        await onSave(params);
        setIsSaving(false);
    };

    const handleRemoveSection = async () => {
        const hasChildren = dataStore.sections.some(s => s.parentId === section.id);
        const confirmationMessage = hasChildren
            ? "Confirm that you would like to permanently delete this section and all children sections. If not, cancel and un-nest the sections before deleting."
            : "Are you sure you want to permanently delete this section?";

        if (window.confirm(confirmationMessage)) {
            setIsRemoving(true);
            const params = {
                removeSections: [section.id]
            };
            await onSave(params);
            setIsRemoving(false);
        }
    };

    const handleNestSection = async (parentId) => {
        setIsNesting(true);
        setShowNestDropdown(false);
        await updateOrderOnNestingChange(section.id, parentId);
        setIsNesting(false);
    };

    const handleUnsetParent = async () => {
        setIsNesting(true);
        setShowNestDropdown(false);
        await updateOrderOnNestingChange(section.id, "");
        setIsNesting(false);
    };

    const isInAncestryChain = (potentialParentId, currentSectionId) => {
        if (potentialParentId === currentSectionId) return true;

        const potentialParent = dataStore.sections.find(s => s.id === potentialParentId);
        if (!potentialParent) return false;

        if (!potentialParent.parentId) return false;

        return isInAncestryChain(potentialParent.parentId, currentSectionId);
    };

    return (
        <div>
            {isEditing ? (
                <div className="d-flex flex-column gap-2 mb-3">
                    <Form.Control
                        type="text"
                        value={localHeading}
                        onChange={(e) => setLocalHeading(e.target.value)}
                        style={{ 
                            fontSize: '1.2rem', 
                            fontWeight: 'bold', 
                            flexGrow: 1, 
                            backgroundColor: '#fdf6e3', // warm off-white
                            color: '#333', 
                            border: '1px solid #ddd' 
                        }}
                    />
                     <div className="d-flex flex-row justify-content-end mb-1">

                        <Button 
                            variant="secondary" 
                            onClick={handleEditToggle} 
                            className="button-save-light"
                            style={{ marginLeft: '10px' }}
                        >
                            Cancel
                        </Button>
                        <Button 
                                variant="secondary" 
                                onClick={handleSaveSection} 
                                className="button-save-dark"
                                style={{ marginLeft: '5px' }}
                                disabled={isSaving}
                            >
                                {isSaving ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        Saving...
                                    </>
                                ) : 'Save'}
                            </Button>
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column gap-2 mb-1">
                    <div className="d-flex flex-row justify-content-between mb-3">
                        <h2 className="form-heading">{section.orderToDisplay}. {section.heading}</h2>
                        {
                            (isMoving) ? 
                            <div className="blinking-dots-small">
                                <span className="dot-small"></span>
                                <span className="dot-small"></span>
                                <span className="dot-small"></span>
                            </div> 
                            : ""
                        }
                        <div className="d-flex flex-row justify-content-end">
                            {section.order > 1 && (
                                <a 
                                    href="#up"
                                    onClick={(e) => { 
                                        e.preventDefault(); 
                                        setIsMoving(true); 
                                        updateOrderManual(section.id, false).then(() => {
                                            setIsMoving(false);
                                        });
                                    }}
                                    className="icon-link"
                                    style={{ marginLeft: '10px' }}
                                >
                                    <Up className="icon-size" />
                                </a>
                            )}
                            {section.order < dataStore.sections.filter(s => section.parentId ? s.parentId === section.parentId : s.level === 1).length && (
                                <a 
                                    href="#down"
                                    onClick={(e) => { 
                                        e.preventDefault(); 
                                        setIsMoving(true); 
                                        updateOrderManual(section.id, true).then(() => {
                                            setIsMoving(false);
                                        });
                                    }}
                                    className="icon-link"
                                    style={{ marginLeft: '10px' }}
                                >
                                    <Down className="icon-size" />
                                </a>
                            )}
                            <div className="position-relative">
                                <Button 
                                    variant="secondary" 
                                    onClick={() => setShowNestDropdown(!showNestDropdown)} 
                                    className="button-save-light"
                                    style={{ marginLeft: '10px' }}
                                    disabled={isNesting}
                                >
                                    {isNesting ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="me-2"
                                            />
                                            Nesting...
                                        </>
                                    ) : 'Nest'}
                                </Button>
                                {showNestDropdown && (
                                    <div className="position-absolute bg-white border rounded shadow-sm" 
                                         style={{ 
                                             top: '100%', 
                                             right: 0, 
                                             zIndex: 1000, 
                                             minWidth: '200px' 
                                         }}>
                                        <Button
                                            variant="link"
                                            className="d-block w-100 text-start px-3 py-2 text-decoration-none border-bottom"
                                            onClick={handleUnsetParent}
                                        >
                                            No parent
                                        </Button>
                                        {dataStore.sections
                                            .filter(s => 
                                                s.id !== section.id && // Don't show current section
                                                !isInAncestryChain(s.id, section.id) // Don't show sections that would create a loop
                                            )
                                            .map(s => (
                                                <Button
                                                    key={s.id}
                                                    variant="link"
                                                    className="d-block w-100 text-start px-3 py-2 text-decoration-none"
                                                    onClick={() => handleNestSection(s.id)}
                                                >
                                                    {s.heading}
                                                </Button>
                                            ))}
                                    </div>
                                )}
                            </div>
                            <Button 
                                variant="secondary" 
                                onClick={handleEditToggle} 
                                className="button-save-light"
                                style={{ marginLeft: '10px' }}
                            >
                                Edit
                            </Button>
                            <Button 
                                variant="secondary" 
                                onClick={handleRemoveSection} 
                                className="button-save-dark"
                                style={{ marginLeft: '5px' }}
                                disabled={isRemoving}
                            >
                                {isRemoving ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        Removing...
                                    </>
                                ) : 'Remove'}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <Form.Label className="label-style-light" >Guidance text</Form.Label>
                {isEditing ? (
                    <Form.Control
                        as="textarea"
                        value={localGuidance}
                        onChange={(e) => setLocalGuidance(e.target.value)}
                        style={{ 
                            backgroundColor: '#fdf6e3', // warm off-white
                            color: '#333', 
                            border: '1px solid #ddd' 
                        }}
                    />
                ) : (
                    <p>{section.guidance || <span className="text-muted">(None)</span>}</p>
                )}
            </div>
            <div className="label-style-light mt-3">
                Body Text
            </div>
            <div>
                {isEditing ? (
                    <div className="editorBox">
                        <Editor
                            tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={replacePlaceholders(localHtml)}
                            init={{
                                branding: false,
                                height: 350,
                                menubar: false,
                                plugins: [
                                    'advlist', 'lists', 'image', 'charmap',
                                    'anchor', 'searchreplace', 'visualblocks', 
                                    'insertdatetime', 'media', 'table', 'asclepia-group-variables'
                                ],
                                toolbar: 'undo redo | h1 h2 h3 | asclepia-group-variables |' +
                                    'bold italic | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | table | image',
                                variables: workspaceData.varSets,
                                description: "",
                                file_picker_types: 'image',
                                image_advtab: true,
                                image_uploadtab: true,
                                images_file_types: 'jpeg,jpg,png,gif',
                                content_css: '/tiny.css',
                            }}
                        />
                    </div>
                ) : (
                    section.html ? (
                        <div dangerouslySetInnerHTML={{ __html: replacePlaceholders(section.html) }} />
                    ) : (
                        <p className="text-muted">(Section empty)</p>
                    )
                )}
            </div>
            <hr />
        </div>
    );
}

export function EditSections() {
    const { documentData, setDocumentData } = useDocumentDataStore();
    const { studyData } = useStudyDataStore();
    const [editingIndex, setEditingIndex] = useState(null);
    const { topData } = useTopDataStore();
    const [isAddingSection, setIsAddingSection] = useState(false);

    const handleAddNewSection = async () => {
        if (isAddingSection) return; // Prevent adding multiple sections at once
        setIsAddingSection(true);

        const order = documentData.sections.filter(s => s.level === 1).length + 1;
        const newSection = {
            html: "",
            heading: "New Section",
            guidance: "",
            order: order,
            level: 1
        };

        setDocumentData(prevData => ({
            ...prevData,
            sections: [...prevData.sections, newSection]
        }));

        const params = {
            sections: [newSection],
        };
        await handleSaveSection(params);
        setIsAddingSection(false);
    };

    const handleEditSection = (index) => {
        setEditingIndex(index);
    };

    const handleCancelEdit = () => {
        setEditingIndex(null);
    };

    const handleSaveSection = async (sectionParams) => {
        // Ensure sectionParams contains all necessary fields
        const sectionsToSave = (sectionParams.sections) ? sectionParams.sections.map(paramSection => {
            const existingSection = documentData.sections.find(section => section.id === paramSection.id);
            return {
                ...existingSection,
                ...paramSection // Override with updated data from sectionParams
            };
        }) : [];

        const params = {
            ...sectionParams,
            id: documentData.id,
            oldVersion: documentData.version,
            name: documentData.name,
            description: documentData.description,
            status: documentData.status,
            email: topData.userEmail,
            groupName: topData.groupName,
            userId: topData.userId,
            sections: sectionsToSave,
        };

        const url = `${topData.endpoint}/document`;

        try {
            const res = await callToBackend(url, params, topData.token, {}, 'PUT');
            if (res.status === 200) {
                const data = await res.json();
                setDocumentData({
                    ...documentData,
                    ...data.document,
                });
            } else {
                const errorData = await res.json();
                alert(errorData.message || 'Error retrieving document.');
            }
        } catch (err) {
            console.error(err);
            alert('Error retrieving document.');
        }
        setEditingIndex(null);
    };


    const updateOrderOnNestingChange = async (sectionId, newParentId) => {
        const sections = [...documentData.sections];
        const section = sections.find(s => s.id === sectionId);
        const oldParent = section.parentId ? sections.find(s => s.id === section.parentId) : null;        
        const newParent = newParentId ? sections.find(s => s.id === newParentId) : null;
        const oldOrder = section.order;
        const updatedSections = [];

        // If moving to become sibling with prior direct parent
        if ((oldParent && oldParent.level === 1 && !newParent)) {
            sections
                .filter(s => s.level === 1 && s.order > oldParent.order)
                .forEach(s => {
                    s.order += 1;
                    updatedSections.push(s);
                });
                section.order = oldParent.order + 1;
        }
        else if ((oldParent && oldParent.parentId === newParentId)) {
            sections
                .filter(s => s.parentId === newParentId && s.order > oldParent.order)
                .forEach(s => {
                    s.order += 1;
                    updatedSections.push(s);
                });
            section.order = oldParent.order + 1;
        } else {
            const newSiblings = sections.filter(s => s.parentId === newParentId);
            section.order = newSiblings.length + 1; 
        }
        section.level = newParentId ? newParent.level + 1 : 1; 
        section.parentId = newParentId;
        updatedSections.push(section);
        // Update the order of (old) siblings in the section it is taken out of
        if (oldParent){
            sections
                .filter(s => s.parentId === oldParent.id && s.order > oldOrder)
                .forEach(s => {
                    s.order -= 1;
                    updatedSections.push(s);
                });
        }
        else {
            sections
                .filter(s => s.level === 1 && s.order > oldOrder)
                .forEach(s => {
                    s.order -= 1;
                    updatedSections.push(s);
                });
        }

        const params = {
            sections: updatedSections,
        };
        await handleSaveSection(params);
        setDocumentData({ ...documentData, sections });
    };

    const updateOrderManual = async (sectionId, forward) => {
        const sections = [...documentData.sections];
        const section = sections.find(s => s.id === sectionId);
        const replaceSection = sections.find(
            s => (section.parentId ? s.parentId === section.parentId : s.level === 1) &&
            (forward ? s.order === section.order + 1 : s.order === section.order - 1));
        const updatedSections = [];
        
        section.order = (forward) ? section.order + 1 : section.order - 1;
        replaceSection.order = (forward) ? section.order - 1 : section.order + 1;
        updatedSections.push(section);
        updatedSections.push(replaceSection);

        const params = {
            sections: updatedSections,
        };
        await handleSaveSection(params);
        setDocumentData({ ...documentData, sections });
    };

    const sortedSections = () => {
        const sections = [...documentData.sections];
        sections.sort((a, b) => a.order - b.order);

        const sorted = [];
        const addSectionWithChildren = (section, parentOrderToDisplay = '') => {
            const orderToDisplay = parentOrderToDisplay ? `${parentOrderToDisplay}.${section.order}` : `${section.order}`;
            section.orderToDisplay = orderToDisplay;
            sorted.push(section);

            const children = sections.filter(s => s.parentId === section.id);
            children.sort((a, b) => a.order - b.order);
            children.forEach(child => {
                if (!sorted.includes(child)) { // Prevent infinite recursion
                    addSectionWithChildren(child, orderToDisplay);
                }
            });
        };

        sections.filter(s => !s.parentId).forEach(section => addSectionWithChildren(section));
        return sorted;
    };

    return (
        <div className="pt-4 px-3">
            <div className="docListItem" id="newDocument">
                <a 
                    href="#id" 
                    onClick={handleAddNewSection} 
                    className="kinetikaOrange"
                    style={{ pointerEvents: isAddingSection ? 'none' : 'auto' }}
                >
                    {isAddingSection ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />
                            Adding Section...
                        </>
                    ) : (
                        <>
                            <Plus style={{color:"#D96320"}}/>Add New Section
                        </>
                    )}
                </a>
            </div>
            {sortedSections().map((section, index) => (
                <div key={index} style={{ marginLeft: `${(section.level - 1) * 2}rem` }}>
                    <DisplaySection
                        section={section}
                        onEdit={() => handleEditSection(index)}
                        onCancel={handleCancelEdit}
                        onSave={handleSaveSection}
                        updateOrderOnNestingChange={updateOrderOnNestingChange}
                        updateOrderManual={updateOrderManual}
                        isEditing={editingIndex === index}
                        dataStore={documentData}
                        studyData={studyData}
                    />
                </div>
            ))}
        </div>
    );
}

export default EditSections;