import './styles/rebrand.css';
import { useTemplateDataStore } from './data/TemplateDataStoreProvider';
import { useTopDataStore } from './data/TopDataStoreProvider';
import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Tabs, Tab, Spinner } from 'react-bootstrap';
import { callToBackend } from './functions/fetch';
import { Editor } from '@tinymce/tinymce-react';
import { useWorkspaceDataStore } from './data/WorkspaceDataStoreProvider';
import { Plus, Up, Down } from './components/Icons';

function Metadata({dataStore, setDataStore, type}) {
    const [isSavingMetadata, setIsSavingMetadata] = useState(false);
    const [isEditingMetadata, setIsEditingMetadata] = useState(false);
    const [localMetadata, setLocalMetadata] = useState({});
    const {topData, setTopData} = useTopDataStore();

    useEffect(() => {
        setLocalMetadata({
            name: dataStore.name,
            description: dataStore.description,
            status: dataStore.status
        });
    }, [dataStore]);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setLocalMetadata(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleEditMetadata = () => {
        setIsEditingMetadata(true);
    };

    const handleCancelEdit = () => {
        setIsEditingMetadata(false);
        setLocalMetadata({
            name: dataStore.name,
            description: dataStore.description,
            status: dataStore.status
        });
    };

    const handleSave = async () => {
        setIsSavingMetadata(true);
        const params = {
            id: dataStore.id,
            oldVersion: dataStore.version,
            name: localMetadata.name,
            description: localMetadata.description,
            status: localMetadata.status,
            email: topData.userEmail,
            groupName: topData.groupName,
            userId: topData.userId
        };
        const url = type = "template" ? `${topData.endpoint}/template` : `${topData.endpoint}/document`;

        try {
            const res = await callToBackend(url, params, topData.token, {}, 'PUT');
            if (res.status === 200) {
                const data = await res.json();
                setTopData({
                    ...topData,
                    page: type = "template" ? "templateText" : "edit",
                });
                const objectOfInterest = type = "template" ? data.template : data.doc;
                setDataStore({
                    ...dataStore,
                    ...objectOfInterest,
                });
            } else {
                const errorData = await res.json();
                alert(errorData.message || `Error retrieving ${type}.`);
            }
        } catch (err) {
            console.error(err);
            alert(`Error retrieving ${type}.`);
        }
        setIsSavingMetadata(false);
        setIsEditingMetadata(false);
    };


    return(
    <div>
                    <Row>
                        <Col> 
                            <p className="text-muted text-start">{dataStore.parent?.id ? `Child of ${dataStore.parent?.id}` : "No parent."}</p>
                            <p className="text-muted text-start">{dataStore.variables.length > 0 ? `Variables: ${dataStore.variables?.join(', ')}` : ""}</p>
                        </Col>
                    </Row>
                    <Form className="d-flex flex-column gap-1">
                        <Form.Group controlId="formName" className="d-flex flex-row gap-2 w-100 align-items-center">
                            <Form.Label className="label-style-light pb-0 mb-0 align-items-center" >Title:</Form.Label>
                            {isEditingMetadata ? (
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={localMetadata.name}
                                    onChange={handleFormChange}
                                    style={{ 
                                        backgroundColor: '#fdf6e3',
                                        color: '#333', 
                                        border: '1px solid #ddd' 
                                    }}
                                />
                            ) : (
                                dataStore.name
                            )}
                        </Form.Group>
                        <Form.Group controlId="formDescription" className="d-flex flex-row gap-2 w-100 align-items-center">
                            <Form.Label className="label-style-light pb-0 mb-0 align-items-center" >Description:</Form.Label>
                            {isEditingMetadata ? (
                                <Form.Control
                                    type="text"
                                    name="description"
                                    value={localMetadata.description}
                                    onChange={handleFormChange}
                                    style={{ 
                                        backgroundColor: '#fdf6e3',
                                        color: '#333', 
                                        border: '1px solid #ddd' 
                                    }}
                                />
                            ) : (
                                dataStore.description
                            )}
                        </Form.Group>
                        <Form.Group controlId="formStatus" className="d-flex flex-row gap-2 w-100 align-items-center">
                            <Form.Label className="label-style-light pb-0 mb-0 align-items-center" >Status:</Form.Label>
                            {isEditingMetadata ? (
                                <Form.Control
                                    as="select"
                                    name="status"
                                    value={localMetadata.status}
                                    onChange={handleFormChange}
                                    style={{ 
                                        backgroundColor: '#fdf6e3',
                                        color: '#333', 
                                        border: '1px solid #ddd' 
                                    }}
                                >
                                    <option value="draft">Draft</option>
                                    <option value="active">Active</option>
                                    <option value="archived">Archived</option>
                                </Form.Control>
                            ) : (
                                dataStore.status
                            )}
                        </Form.Group>
                        {isEditingMetadata ? (
                                <div className="d-flex flex-row gap-2 mt-3">
                                    <Button 
                                        variant="secondary" 
                                        onClick={handleCancelEdit}
                                        className="button-save-light"
                                    >
                                        Cancel
                                    </Button>
                                    <Button 
                                        variant="secondary" 
                                        onClick={handleSave} 
                                        className="button-save-dark"
                                        disabled={isSavingMetadata}
                                    >
                                        {isSavingMetadata ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="me-2"
                                                />
                                                Saving...
                                            </>
                                        ) : 'Save'}
                                    </Button>
                                </div>
                        ) : (
                                <div className="d-flex flex-row gap-2 mt-3">
                                    <Button 
                                        variant="secondary" 
                                        onClick={handleEditMetadata}
                                        className="button-save-light"
                                    >
                                        Edit
                                    </Button>
                                </div>
                        )}
                    </Form>
       </div>
    );
}
function DisplaySection({ section, onEdit, onCancel, onSave, isEditing, dataStore, updateOrderOnNestingChange, updateOrderManual }) {
    const [localHtml] = useState(section.html);
    const [localHeading, setLocalHeading] = useState(section.heading);
    const [localGuidance, setLocalGuidance] = useState(section.guidance);
    const { workspaceData } = useWorkspaceDataStore();
    const editorRef = React.useRef(null);
    const [isSaving, setIsSaving] = useState(false);
    const [showNestDropdown, setShowNestDropdown] = useState(false);
    const [isNesting, setIsNesting] = useState(false);
    const [localOrder] = useState(section.order);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isMoving, setIsMoving] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowNestDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const getParentLevel = () => {
        if (!section.parentId) return 0; 
        const parentSection = dataStore.sections.find(s => s.id === section.parentId);
        return parentSection ? parentSection.level : section.parentId;
    }

    const handleEditToggle = () => {
        if (isEditing) {
            onCancel();
        } else {
            onEdit();
        }
    };

    const handleSaveSection = async () => {
        setIsSaving(true);
        const params = {
            sections: [{
                id: section.id,
                version: section.version,
                heading: localHeading,
                guidance: localGuidance,
                html: editorRef.current ? editorRef.current.getContent() : localHtml,
                parentId: section.parentId,
                order: localOrder,
                level: section.parentId ? getParentLevel() + 1 : 1,
            }]
        };
        await onSave(params);
        setIsSaving(false);
    };

    const handleRemoveSection = async () => {
        const hasChildren = dataStore.sections.some(s => s.parentId === section.id);
        const confirmationMessage = hasChildren
            ? "Confirm that you would like to permanently delete this section and all children sections. If not, cancel and un-nest the sections before deleting."
            : "Are you sure you want to permanently delete this section?";

        if (window.confirm(confirmationMessage)) {
            setIsRemoving(true);
            const params = {
                removeSections: [section.id]
            };
            await onSave(params);
            setIsRemoving(false);
        }
    };

    const handleNestSection = async (parentId) => {
        setIsNesting(true);
        setShowNestDropdown(false);
        await updateOrderOnNestingChange(section.id, parentId);
        setIsNesting(false);
    };

    const handleUnsetParent = async () => {
        setIsNesting(true);
        setShowNestDropdown(false);
        await updateOrderOnNestingChange(section.id, "");
        setIsNesting(false);
    };

    const isInAncestryChain = (potentialParentId, currentSectionId) => {
        if (potentialParentId === currentSectionId) return true;

        const potentialParent = dataStore.sections.find(s => s.id === potentialParentId);
        if (!potentialParent) return false;

        if (!potentialParent.parentId) return false;

        return isInAncestryChain(potentialParent.parentId, currentSectionId);
    };

    return (
        <div>
            {isEditing ? (
                <div className="d-flex flex-column gap-2 mb-3">
                    <Form.Control
                        type="text"
                        value={localHeading}
                        onChange={(e) => setLocalHeading(e.target.value)}
                        style={{ 
                            fontSize: '1.2rem', 
                            fontWeight: 'bold', 
                            flexGrow: 1, 
                            backgroundColor: '#fdf6e3', // warm off-white
                            color: '#333', 
                            border: '1px solid #ddd' 
                        }}
                    />
                     <div className="d-flex flex-row justify-content-end mb-1">

                        <Button 
                            variant="secondary" 
                            onClick={handleEditToggle} 
                            className="button-save-light"
                            style={{ marginLeft: '10px' }}
                        >
                            Cancel
                        </Button>
                        <Button 
                                variant="secondary" 
                                onClick={handleSaveSection} 
                                className="button-save-dark"
                                style={{ marginLeft: '5px' }}
                                disabled={isSaving}
                            >
                                {isSaving ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        Saving...
                                    </>
                                ) : 'Save'}
                            </Button>
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column gap-2 mb-1">
                    <div className="d-flex flex-row justify-content-between mb-3">
                        <h2 className="form-heading">{section.orderToDisplay}. {section.heading}</h2>
                        {
                            (isMoving) ? 
                            <div className="blinking-dots-small">
                                <span className="dot-small"></span>
                                <span className="dot-small"></span>
                                <span className="dot-small"></span>
                            </div> 
                            : ""
                        }
                        <div className="d-flex flex-row justify-content-end">
                            {section.order > 1 && (
                                <a 
                                    href="#up"
                                    onClick={(e) => { 
                                        e.preventDefault(); 
                                        setIsMoving(true); 
                                        updateOrderManual(section.id, false).then(() => {
                                            setIsMoving(false);
                                        });
                                    }}
                                    className="icon-link"
                                    style={{ marginLeft: '10px' }}
                                >
                                    <Up className="icon-size" />
                                </a>
                            )}
                            {section.order < dataStore.sections.filter(s => section.parentId ? s.parentId === section.parentId : s.level === 1).length && (
                                <a 
                                    href="#down"
                                    onClick={(e) => { 
                                        e.preventDefault(); 
                                        setIsMoving(true); 
                                        updateOrderManual(section.id, true).then(() => {
                                            setIsMoving(false);
                                        });
                                    }}
                                    className="icon-link"
                                    style={{ marginLeft: '10px' }}
                                >
                                    <Down className="icon-size" />
                                </a>
                            )}
                            <div className="position-relative" ref={dropdownRef}>
                                <Button 
                                    variant="secondary" 
                                    onClick={() => setShowNestDropdown(!showNestDropdown)} 
                                    className="button-save-light"
                                    style={{ marginLeft: '10px' }}
                                    disabled={isNesting}
                                >
                                    {isNesting ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="me-2"
                                            />
                                            Nesting...
                                        </>
                                    ) : 'Nest'}
                                </Button>
                                {showNestDropdown && (
                                    <div className="position-absolute bg-white border rounded shadow-sm" 
                                         style={{ 
                                             top: '100%', 
                                             right: 0, 
                                             zIndex: 1000, 
                                             minWidth: '200px' 
                                         }}>
                                        {section.level > 1 && (
                                            <Button
                                                variant="link"
                                                className="d-block w-100 text-start px-3 py-2 text-decoration-none border-bottom"
                                                onClick={handleUnsetParent}
                                            >
                                                No parent
                                            </Button>
                                        )}
                                        {dataStore.sections
                                            .filter(s => 
                                                s.id !== section.id && // Don't show current section
                                                !isInAncestryChain(s.id, section.id) && // Don't show sections that would create a loop
                                                s.id !== section.parentId // Don't show current parent
                                            )
                                            .sort((a, b) => a.orderToDisplay.localeCompare(b.orderToDisplay)) // Sort by orderToDisplay
                                            .map(s => (
                                                <Button
                                                    key={s.id}
                                                    variant="link"
                                                    className="d-block w-100 text-start px-3 py-2 text-decoration-none"
                                                    onClick={() => handleNestSection(s.id)}
                                                >
                                                    {s.orderToDisplay}. {s.heading}
                                                </Button>
                                            ))}
                                    </div>
                                )}
                            </div>
                            <Button 
                                variant="secondary" 
                                onClick={handleEditToggle} 
                                className="button-save-light"
                                style={{ marginLeft: '10px' }}
                            >
                                Edit
                            </Button>
                            <Button 
                                variant="secondary" 
                                onClick={handleRemoveSection} 
                                className="button-save-dark"
                                style={{ marginLeft: '5px' }}
                                disabled={isRemoving}
                            >
                                {isRemoving ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        Removing...
                                    </>
                                ) : 'Remove'}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <Form.Label className="label-style-light" >Guidance text</Form.Label>
                {isEditing ? (
                    <Form.Control
                        as="textarea"
                        value={localGuidance}
                        onChange={(e) => setLocalGuidance(e.target.value)}
                        style={{ 
                            backgroundColor: '#fdf6e3', // warm off-white
                            color: '#333', 
                            border: '1px solid #ddd' 
                        }}
                    />
                ) : (
                    <p>{section.guidance || <span className="text-muted">(None)</span>}</p>
                )}
            </div>
            <div className="label-style-light mt-3">
                Body Text
            </div>
            <div>
                {isEditing ? (
                    <div className="editorBox">
                        <Editor
                            tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={localHtml}
                            init={{
                                branding: false,
                                height: 350,
                                menubar: false,
                                plugins: [
                                    'advlist', 'lists', 'image', 'charmap',
                                    'anchor', 'searchreplace', 'visualblocks', 
                                    'insertdatetime', 'media', 'table', 'asclepia-group-variables'
                                ],
                                toolbar: 'undo redo | h1 h2 h3 | asclepia-group-variables |' +
                                    'bold italic | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | table | image',
                                variables: workspaceData.varSets,
                                description: "",
                                file_picker_types: 'image',
                                image_advtab: true,
                                image_uploadtab: true,
                                images_file_types: 'jpeg,jpg,png,gif',
                                content_css: '/tiny.css',
                            }}
                        />
                    </div>
                ) : (
                    section.html ? (
                        <div dangerouslySetInnerHTML={{ __html: section.html }} />
                    ) : (
                        <p className="text-muted">(Section empty)</p>
                    )
                )}
            </div>
            <hr />
        </div>
    );
}

function EditSections() {
    const { templateData, setTemplateData } = useTemplateDataStore();
    const [editingIndex, setEditingIndex] = useState(null);
    const { topData } = useTopDataStore();
    const [isAddingSection, setIsAddingSection] = useState(false);

    const handleAddNewSection = async () => {
        if (isAddingSection) return; // Prevent adding multiple sections at once
        setIsAddingSection(true);

        const order = templateData.sections.filter(s => s.level === 1).length + 1;
        const newSection = {
            html: "",
            heading: "New Section",
            guidance: "",
            order: order,
            level: 1
        };

        setTemplateData(prevData => ({
            ...prevData,
            sections: [...prevData.sections, newSection]
        }));

        const params = {
            sections: [newSection],
        };
        await handleSaveSection(params);
        setIsAddingSection(false);
    };

    const handleEditSection = (index) => {
        setEditingIndex(index);
    };

    const handleCancelEdit = () => {
        setEditingIndex(null);
    };

    const handleSaveSection = async (sectionParams) => {
        // Ensure sectionParams contains all necessary fields
        const sectionsToSave = (sectionParams.sections) ? sectionParams.sections.map(paramSection => {
            const existingSection = templateData.sections.find(section => section.id === paramSection.id);
            return {
                ...existingSection,
                ...paramSection // Override with updated data from sectionParams
            };
        }) : [];

        const params = {
            ...sectionParams,
            id: templateData.id,
            oldVersion: templateData.version,
            name: templateData.name,
            description: templateData.description,
            status: templateData.status,
            email: topData.userEmail,
            groupName: topData.groupName,
            userId: topData.userId,
            sections: sectionsToSave,
        };

        const url = `${topData.endpoint}/template`;

        try {
            const res = await callToBackend(url, params, topData.token, {}, 'PUT');
            if (res.status === 200) {
                const data = await res.json();
                setTemplateData({
                    ...templateData,
                    ...data.template,
                });
            } else {
                const errorData = await res.json();
                alert(errorData.message || 'Error retrieving template.');
            }
        } catch (err) {
            console.error(err);
            alert('Error retrieving template.');
        }
        setEditingIndex(null);
    };


    const updateOrderOnNestingChange = async (sectionId, newParentId) => {
        const sections = [...templateData.sections];
        const section = sections.find(s => s.id === sectionId);
        const oldParent = section.parentId ? sections.find(s => s.id === section.parentId) : null;        
        const newParent = newParentId ? sections.find(s => s.id === newParentId) : null;
        const oldOrder = section.order;
        const updatedSections = [];

        // If moving to become sibling with prior direct parent
        if ((oldParent && oldParent.level === 1 && !newParent)) {
            sections
                .filter(s => s.level === 1 && s.order > oldParent.order)
                .forEach(s => {
                    s.order += 1;
                    updatedSections.push(s);
                });
                section.order = oldParent.order + 1;
        }
        else if ((oldParent && oldParent.parentId === newParentId)) {
            sections
                .filter(s => s.parentId === newParentId && s.order > oldParent.order)
                .forEach(s => {
                    s.order += 1;
                    updatedSections.push(s);
                });
            section.order = oldParent.order + 1;
        } else {
            const newSiblings = sections.filter(s => s.parentId === newParentId);
            section.order = newSiblings.length + 1; 
        }
        section.level = newParentId ? newParent.level + 1 : 1; 
        section.parentId = newParentId;
        updatedSections.push(section);
        // Update the order of (old) siblings in the section it is taken out of
        if (oldParent){
            sections
                .filter(s => s.parentId === oldParent.id && s.order > oldOrder)
                .forEach(s => {
                    s.order -= 1;
                    updatedSections.push(s);
                });
        }
        else {
            sections
                .filter(s => s.level === 1 && s.order > oldOrder)
                .forEach(s => {
                    s.order -= 1;
                    updatedSections.push(s);
                });
        }

        const params = {
            sections: updatedSections,
        };
        await handleSaveSection(params);
        setTemplateData({ ...templateData, sections });
    };

    const updateOrderManual = async (sectionId, forward) => {
        const sections = [...templateData.sections];
        const section = sections.find(s => s.id === sectionId);
        const replaceSection = sections.find(
            s => (section.parentId ? s.parentId === section.parentId : s.level === 1) &&
            (forward ? s.order === section.order + 1 : s.order === section.order - 1));
        const updatedSections = [];
        
        section.order = (forward) ? section.order + 1 : section.order - 1;
        replaceSection.order = (forward) ? section.order - 1 : section.order + 1;
        updatedSections.push(section);
        updatedSections.push(replaceSection);

        const params = {
            sections: updatedSections,
        };
        await handleSaveSection(params);
        setTemplateData({ ...templateData, sections });
    };

    const sortedSections = () => {
        const sections = [...templateData.sections];
        
        // Ensure all sections have an orderToDisplay before sorting
        sections.forEach(section => {
            if (!section.orderToDisplay) {
                section.orderToDisplay = section.order.toString();
            }
        });

        sections.sort((a, b) => a.order - b.order);

        const sorted = [];
        const addSectionWithChildren = (section, parentOrderToDisplay = '') => {
            const orderToDisplay = parentOrderToDisplay ? `${parentOrderToDisplay}.${section.order}` : `${section.order}`;
            section.orderToDisplay = orderToDisplay;
            sorted.push(section);

            const children = sections.filter(s => s.parentId === section.id);
            children.sort((a, b) => a.order - b.order);
            children.forEach(child => {
                if (!sorted.includes(child)) { // Prevent infinite recursion
                    addSectionWithChildren(child, orderToDisplay);
                }
            });
        };

        sections.filter(s => !s.parentId).forEach(section => addSectionWithChildren(section));
        return sorted;
    };

    return (
        <div className="pt-4 px-3">
            <div className="docListItem" id="newTemplate">
                <a 
                    href="#id" 
                    onClick={handleAddNewSection} 
                    className="kinetikaOrange"
                    style={{ pointerEvents: isAddingSection ? 'none' : 'auto' }}
                >
                    {isAddingSection ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />
                            Adding Section...
                        </>
                    ) : (
                        <>
                            <Plus style={{color:"#D96320"}}/>Add New Section
                        </>
                    )}
                </a>
            </div>
            {sortedSections().map((section, index) => {
                const key = `${section.id || 'new'}-${index}`; // Combine section.id with index
                return (
                    <div key={key} style={{ marginLeft: `${(section.level - 1) * 2}rem` }}>
                        <DisplaySection
                            section={section}
                            onEdit={() => handleEditSection(index)}
                            onCancel={handleCancelEdit}
                            onSave={handleSaveSection}
                            updateOrderOnNestingChange={updateOrderOnNestingChange}
                            updateOrderManual={updateOrderManual}
                            isEditing={editingIndex === index}
                            dataStore={templateData}
                        />
                    </div>
                );
            })}
        </div>
    );
}

export default function TemplateText() {
    const { templateData, setTemplateData } = useTemplateDataStore();
    const { topData } = useTopDataStore();

    const [activeTab, setActiveTab] = useState('metadata');

    const handleTabSelect = (key) => {
        if (key === 'contents') {
            alert("Feature coming soon.");
        } else if (key === 'save') {
            alert("For now, simple saves are happening every time you save form fields and text. Advanced saves, including versioning and amendments tables, is coming soon.");
        } else {
            setActiveTab(key);
        }
    };
    console.log(topData);
    return (
        <Container>
            <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="mb-3">
                <Tab eventKey="metadata" title="Metadata">
                    <Metadata dataStore={templateData} setDataStore={setTemplateData} type="template" />  
                </Tab>
                <Tab eventKey="text" title="Text">
                    <EditSections />
                </Tab>
            </Tabs>
        </Container>
    );
}
