import './App.css';
import { useEffect } from "react";
import { useTopDataStore } from "./data/TopDataStoreProvider";
import { useDocumentDataStore } from './data/DocumentDataStoreProvider';
import { useWorkspaceDataStore } from './data/WorkspaceDataStoreProvider';
import { Container, Row, Spinner } from 'react-bootstrap';
import { Trash, Copy } from './img/Vectors';
import { gotoDocument } from './functions/documents';
import { deleteDoc, cloneDoc } from './functions/document';
import { useLoadDataStore } from './data/LoadDataStoreProvider';
import { fetchDataForWorkspace } from './functions/workspace';

function Documents() {
  const { topData, setTopData } = useTopDataStore();
  const { documentData, setDocumentData } = useDocumentDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();

  // Catch errors if workspaceData.docs were not loaded correctly
  if (!workspaceData.docs) {  
    return (
        <p>Loading documents.</p>                      
    );
  } 
  else {
    workspaceData.docs.sort((a, b) => (a.documentName > b.documentName) ? 1 : -1);
    return workspaceData.docs.map((docData) => {
      if (docData.id) {
        return (
          <div className="docListItem" key={docData.id}>
            <div className="docTextBlock">
              <a href="#id" data-id={docData.id} onClick={(e) => gotoDocument(e, topData, setTopData, documentData, setDocumentData)}>
                {docData.documentName || 'Unnamed Document'}
              </a> &nbsp;
              <span className="docListItemDetail">
                Based on {docData.template_name} v. {docData.template_version}
              </span>
            </div>
            <a href="/delete-doc" 
              onClick={(e) => deleteDoc(e, docData.id, docData.documentName, workspaceData, setWorkspaceData, topData)} 
              className="trashButtonList"
            >
              <Trash />          
            </a>
            { (topData.cloning && docData.id === documentData.cloning) ? 
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />      
              :
              <a href="/clone-doc"
                onClick={(e) => cloneDoc(e, docData.id, docData.documentName, docData.version, documentData, setDocumentData, workspaceData, setWorkspaceData, topData)}
              >
                <Copy />  
              </a>
            }
          </div>
        );
      }
      return null;
    });
  }
}

export default function DocumentList() {

  const { topData } = useTopDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
  const { setLoadData } = useLoadDataStore();

  useEffect(() => {
    fetchDataForWorkspace(topData, workspaceData, setWorkspaceData, setLoadData, ["S"])
  }, []); // Run once on load

  return (
    <Container className="mainPage">
      <Row>
        <h2 className="pageTitle">
          Documents
        </h2>
      </Row>
      <Row> 
        <Documents />    
      </Row>
    </Container>
  );
}