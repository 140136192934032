import './styles/rebrand.css';
import { useDocumentDataStore } from './data/DocumentDataStoreProvider';
import { useTopDataStore } from './data/TopDataStoreProvider';
import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Tabs, Tab, Spinner } from 'react-bootstrap';
import { callToBackend } from './functions/fetch';
import { useStudyDataStore } from './data/StudyDataStoreProvider';
import { EditSections } from './components/EditDocument';

function Metadata({dataStore, setDataStore, type}) {
    const [isSavingMetadata, setIsSavingMetadata] = useState(false);
    const [isEditingMetadata, setIsEditingMetadata] = useState(false);
    const [localMetadata, setLocalMetadata] = useState({});
    const {topData, setTopData} = useTopDataStore();
    const {studyData} = useStudyDataStore();

    useEffect(() => {
        setLocalMetadata({
            name: dataStore.name,
            description: dataStore.description,
            type: dataStore.type,
            status: dataStore.status
        });
    }, [dataStore]);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setLocalMetadata(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleEditMetadata = () => {
        setIsEditingMetadata(true);
    };

    const handleCancelEdit = () => {
        setIsEditingMetadata(false);
        setLocalMetadata({
            name: dataStore.name,
            description: dataStore.description,
            status: dataStore.status
        });
    };

    const handleSave = async () => {
        setIsSavingMetadata(true);
        const params = {
            id: dataStore.id,
            oldVersion: dataStore.version,
            name: localMetadata.name,
            description: localMetadata.description,
            type: localMetadata.type,
            status: localMetadata.status,
            email: topData.userEmail,
            groupName: topData.groupName,
            userId: topData.userId
        };
        const url = type = "document" ? `${topData.endpoint}/document` : `${topData.endpoint}/document`;

        try {
            const res = await callToBackend(url, params, topData.token, {}, 'PUT');
            if (res.status === 200) {
                const data = await res.json();
                setTopData({
                    ...topData,
                    page: type = "edit",
                });
                const objectOfInterest = type = "document" ? data.document : data.doc;
                setDataStore({
                    ...dataStore,
                    ...objectOfInterest,
                });
            } else {
                const errorData = await res.json();
                alert(errorData.message || `Error retrieving ${type}.`);
            }
        } catch (err) {
            console.error(err);
            alert(`Error retrieving ${type}.`);
        }
        setIsSavingMetadata(false);
        setIsEditingMetadata(false);
    };

    console.log(studyData);
    return(
    <div>
                    <Form className="d-flex flex-column gap-1 pt-4">
                        <Form.Group controlId="formName" className="d-flex flex-row gap-2 w-100 align-items-center">
                            <Form.Label className="label-style-light pb-0 mb-0 align-items-center" >Title:</Form.Label>
                            {isEditingMetadata ? (
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={localMetadata.name}
                                    onChange={handleFormChange}
                                    style={{ 
                                        backgroundColor: '#fdf6e3',
                                        color: '#333', 
                                        border: '1px solid #ddd' 
                                    }}
                                />
                            ) : (
                                dataStore.name
                            )}
                        </Form.Group>
                        <Form.Group controlId="formDescription" className="d-flex flex-row gap-2 w-100 align-items-center">
                            <Form.Label className="label-style-light pb-0 mb-0 align-items-center" >Description:</Form.Label>
                            {isEditingMetadata ? (
                                <Form.Control
                                    type="text"
                                    name="description"
                                    value={localMetadata.description}
                                    onChange={handleFormChange}
                                    style={{ 
                                        backgroundColor: '#fdf6e3',
                                        color: '#333', 
                                        border: '1px solid #ddd' 
                                    }}
                                />
                            ) : (
                                dataStore.description
                            )}
                        </Form.Group>
                        <Form.Group controlId="formType" className="d-flex flex-row gap-2 w-100 align-items-center">
                            <Form.Label className="label-style-light pb-0 mb-0 align-items-center" >Type:</Form.Label>
                            {isEditingMetadata ? (
                                <Form.Control
                                    type="text"
                                    name="type"
                                    value={localMetadata.type}
                                    onChange={handleFormChange}
                                    style={{ 
                                        backgroundColor: '#fdf6e3',
                                        color: '#333', 
                                        border: '1px solid #ddd' 
                                    }}
                                />
                            ) : (
                                dataStore.type
                            )}
                        </Form.Group>
                        <Form.Group controlId="formStatus" className="d-flex flex-row gap-2 w-100 align-items-center">
                            <Form.Label className="label-style-light pb-0 mb-0 align-items-center" >Status:</Form.Label>
                            {isEditingMetadata ? (
                                <Form.Control
                                    as="select"
                                    name="status"
                                    value={localMetadata.status}
                                    onChange={handleFormChange}
                                    style={{ 
                                        backgroundColor: '#fdf6e3',
                                        color: '#333', 
                                        border: '1px solid #ddd' 
                                    }}
                                >
                                    <option value="draft">Draft</option>
                                    <option value="active">Active</option>
                                    <option value="archived">Archived</option>
                                </Form.Control>
                            ) : (
                                dataStore.status
                            )}
                        </Form.Group>
                        {isEditingMetadata ? (
                                <div className="d-flex flex-row gap-2 mt-3">
                                    <Button 
                                        variant="secondary" 
                                        onClick={handleCancelEdit}
                                        className="button-save-light"
                                    >
                                        Cancel
                                    </Button>
                                    <Button 
                                        variant="secondary" 
                                        onClick={handleSave} 
                                        className="button-save-dark"
                                        disabled={isSavingMetadata}
                                    >
                                        {isSavingMetadata ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="me-2"
                                                />
                                                Saving...
                                            </>
                                        ) : 'Save'}
                                    </Button>
                                </div>
                        ) : (
                                <div className="d-flex flex-row gap-2 mt-3">
                                    <Button 
                                        variant="secondary" 
                                        onClick={handleEditMetadata}
                                        className="button-save-light"
                                    >
                                        Edit
                                    </Button>
                                </div>
                        )}
                    </Form>
       </div>
    );
}

export default function DocumentText() {
    const { documentData, setDocumentData } = useDocumentDataStore();

    const [activeTab, setActiveTab] = useState('metadata');

    const handleTabSelect = (key) => {
        if (key === 'contents') {
            alert("Feature coming soon.");
        } else if (key === 'save') {
            alert("For now, simple saves are happening every time you save form fields and text. Advanced saves, including versioning and amendments tables, is coming soon.");
        } else {
            setActiveTab(key);
        }
    };

    return (
        <Container>
            <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="mb-3">
                <Tab eventKey="metadata" title="Metadata">
                    <Metadata dataStore={documentData} setDataStore={setDocumentData} type="document" />  
                </Tab>
                <Tab eventKey="text" title="Text">
                    <EditSections />
                </Tab>
            </Tabs>
        </Container>
    );
}
