import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

export default function PasswordModal({ show, handleClose }) {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [retypeNewPassword, setRetypeNewPassword] = useState('');

    useEffect(() => {
        if (show) {
            setOldPassword('');
            setNewPassword('');
            setRetypeNewPassword('');
        }
    }, [show]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (newPassword !== retypeNewPassword) {
            alert("The new passwords do not match.");
            return;
        }

        const passwordCriteria = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})(^\S*$)/; // positive look ahead: 0-9 digits
                                                                              // positive look ahead; one special char.
                                                                              // positive lookahead at least 12 char.
                                                                              // no white space in entire string
        if (!passwordCriteria.test(newPassword)) {
            alert("Password must be at least 12 characters long, include at least one number and one special character (!@#$%^&*), and contain no spaces.");
            return;
        }

        // TO ADD: Validate old password
        console.log({
            oldPassword,
            newPassword,
            retypeNewPassword
        });

        // TO DO: Add new password workflow.
        alert("Functionality coming soon.");
    };

    return (
        <Modal 
            show={show} 
            onHide={handleClose} 
            size="lg"
            className="dark-modal"
            backdropClassName="dark-backdrop"
        >
            <Modal.Header 
                closeButton={false}
                style={{ borderBottom: 'none' }}
            >
                <Modal.Title className="modal-form-title">Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
                    <Form.Group controlId="formOldPassword" className="d-flex flex-column gap-0 align-items-start">
                        <Form.Label className="label-style-dark">Old Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter old password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            className="form-control-dark"
                            disabled
                        />
                    </Form.Group>
                    <Form.Group controlId="formNewPassword" className="d-flex flex-column gap-0 align-items-start">
                        <Form.Label className="label-style-dark">New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="form-control-dark"
                            disabled
                        />
                        <Form.Text className="text-muted text-start">
                        Password must be at least 12 characters long, include at least one number and one special character (!@#$%^&*), and contain no spaces.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formRetypeNewPassword" className="d-flex flex-column gap-0 align-items-start">
                        <Form.Label className="label-style-dark">Retype New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Retype new password"
                            value={retypeNewPassword}
                            onChange={(e) => setRetypeNewPassword(e.target.value)}
                            className="form-control-dark"
                            disabled
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="primary" 
                    onClick={handleClose}
                    className="button-cancel-dark"
                >
                    Cancel
                </Button>
                <Button 
                    variant="secondary" 
                    type="submit"
                    onClick={handleSubmit}
                    className="button-save-dark"
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
