import AsclepiaLib from "./AsclepiaLib";
import { fetchDataForWorkspace } from "./workspace";

export function refreshLocalDocs(workspaceData, setWorkspaceData, topData, setLoadData) {
  fetchDataForWorkspace(topData, workspaceData, setWorkspaceData, setLoadData, ["S"])
  const companyDocuments = AsclepiaLib.deepCopy(workspaceData.docs); 
  // Retaining, but in the process of deleting companyDocuments
  companyDocuments.sort((a, b) => (a.documentName > b.documentName) ? 1 : -1);
  setWorkspaceData({
    ...workspaceData,
    companyDocuments: companyDocuments,
  });
}

export function gotoDocument(event, topData, setTopData, documentData, setDocumentData) {
  event.preventDefault();
  
  setTopData({
    ...topData,
    page: 'documentPageDisambiguate',
  });

  setDocumentData({
    ...documentData,
    id: event.target.dataset.id,
  });
}
