import './styles/login.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useTopDataStore } from './data/TopDataStoreProvider';
import { useEffect, useState } from "react";
import { statusGET } from "./functions/api-calls";

function Offline() {

  const { topData, setTopData } = useTopDataStore();

  const [dupCheck, setDupCheck] = useState(true);
  const [interval, setInterval] = useState(null);
  useEffect(() => {
    if (dupCheck) {
      setDupCheck(false);
      setInterval(() => {
        if (topData.AccessKeyId) {
          statusGET(topData, setTopData, interval);
        } 
      }, 120000);
    
      return () => {
        setTimeout(() => {
          clearInterval(interval);     
        }, 1800000);
      }    
    }
  }, []);  
  
  return (
    <Container fluid>
      <Row>
        <Col xs={6}>
          <img src="/img/white.png" style={{width: "60%", height: "auto"}} alt="logo" />
        </Col>
        <Col xs={6} className="login-container" style={{backgroundColor:"#ffffff"}}>
          <Container className="p-3">
            <Row> 
              <Col>
                <h1 className="h3 mb-3 fw-normal">Asclepia Kinetika</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Files and databases are being updated&hellip; we'll be back momentarily.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <img src="/img/offline.gif" style={{maxWidth:"100%",height:"auto"}} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>   
  );
}

export default Offline;