import React from 'react';
import { Collapse, Expand, Trash, Pencil } from './Icons';

export default function VarsetCard({
    varset,
    index,
    expandedIndices,
    expandedVarIndices,
    toggleExpand,
    toggleVarExpand,
    deleteVariable,
    handleEditClick
}) {
    return (
        <div key={index} className="listBlockWrapper" style={{ width: '45%' }}>
            <div onClick={() => toggleExpand(index)} style={{ fontSize: '120%' }}>
                {expandedIndices.includes(index) ? <Collapse /> : <Expand />}
                <strong>{varset.name}</strong>
            </div>
            {expandedIndices.includes(index) && (
                <div className="d-flex flex-column gap-2 mt-1 ms-4">
                    <div className="d-flex justify-content-between pt-2 pb-2">
                        <div className="d-flex text-muted small fst-italic pt-1">
                            <div>
                                <em>v.{varset.version}</em>, last updated {new Date(varset.updated_at).toLocaleString()}
                            </div>
                        </div>
                        <div className="d-inline-flex align-items-center justify-content-between">
                            <div className="d-flex gap-1">
                                <button onClick={(e) => { e.stopPropagation(); deleteVariable(e, varset.id, varset.version); }} className="btn btn-link p-0">
                                    <Trash />
                                </button>
                                <button onClick={(e) => { e.stopPropagation(); handleEditClick(varset); }} className="btn btn-link p-0">
                                    <Pencil />
                                </button>
                            </div>
                        </div>
                    </div>
                    {varset.description ? <div><strong>Description:</strong> {varset.description}</div> : ""}
                    <div>
                        <strong>Category:</strong> {varset.form_section}
                    </div>
                    <div>
                        <strong>Multiple instances:</strong>
                        <span style={{ marginLeft: '5px', fontWeight: varset.multiple ? 'bold' : 'normal' }}>
                            {varset.multiple ? "Yes" : "No"}
                        </span>
                    </div>
                    <div className="d-flex flex-column gap-2 mt-1 mb-3 p-0 ms-0">
                        {varset.variables
                            .sort((a, b) => (a.variant || "Default").localeCompare(b.variant || "Default"))
                            .map((j, jIndex) => (
                                <div key={jIndex} className="p-0 m-0">
                                    <div className="d-inline-flex align-items-center col-12 m-0 p-0">
                                        <div className="ps-1 pe-0 me-0" onClick={() => toggleVarExpand(index, jIndex)}>
                                            {expandedVarIndices[index] && expandedVarIndices[index].includes(jIndex) ? <Collapse /> : <Expand />}
                                        </div>
                                        <div className="align-items-center pt-1">
                                            <strong>{j.variant || "Default"}</strong>
                                        </div>
                                        <div className="ms-2 pt-1"><em>v.{j.version}</em></div>
                                    </div>
                                    {expandedVarIndices[index] && expandedVarIndices[index].includes(jIndex) && (
                                        <div className="mt-2 ms-4 d-flex flex-column gap-2">
                                            {j.placeholder ? <div ><strong>Placeholder:</strong> {j.placeholder}</div> : ""}
                                            {j.example ? <div><strong>Example:</strong> {j.example}</div> : ""}
                                            {!j.select ? "" :
                                                <div className="d-flex flex-column gap-2">
                                                    <div><strong>Multiple choice</strong></div>
                                                    <div className="d-flex flex-column gap-2 ps-4">
                                                        {j.select && j.options.map((option, optionIndex) => (
                                                            <div key={optionIndex}>{option}</div>
                                                        ))}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
}
