/*
  Document data store maintains data about an open/active document during editing,
  including any variable values.
  Data related to documents available within a workspace are stored in WorkspaceDataStore.
*/

import { createContext, useState, useContext } from "react";

const DocumentDataStore = createContext();
export const useDocumentDataStore = () => useContext(DocumentDataStore);

export const emptyDocument = {
  id: "",
  version: 1,
  name: "",
  description: "",
  type: "",
  templateId: "",
  templateVersion: 1,
  variables: []
};

export default function DocumentDataStoreProvider({children}) {
  const [documentData, setDocumentData] = useState(emptyDocument);
  
  return (
    <DocumentDataStore.Provider value={{ documentData, setDocumentData }}>
      {children}
    </DocumentDataStore.Provider>
  );
}