/*
  Study data store maintains data about an open/active study during editing,
  including any variable values.
  Data related to studys available within a workspace are stored in WorkspaceDataStore.
*/

import { createContext, useState, useContext } from "react";

const StudyDataStore = createContext();
export const useStudyDataStore = () => useContext(StudyDataStore);

export const emptyStudy = {
  id: "",
  version: 1,
  name: "",
  description: "",
  parent: {
    id: "",
    version: 1
  },
  values: {}
};

export default function StudyDataStoreProvider({children}) {
  const [studyData, setStudyData] = useState(emptyStudy);
  
  return (
    <StudyDataStore.Provider value={{ studyData, setStudyData }}>
      {children}
    </StudyDataStore.Provider>
  );
}