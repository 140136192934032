/*
  Repository for all data related to variable definitions and associated variable sets 
  A variable definition is the "container" or format a particular 
  variable can take. This is reusable across templates and saved at a workspace level.
  A variable set is a collection of associated variable definitions with a name.
  A variable value is a particular value assigned to a given variable definition within
  a given document or study. Variable values are stored at the document level and 
  therefore would be stored if needed in the DocumentDataStoreProvider.
*/ 

import { createContext, useState, useContext } from "react";

const VariableDataStore = createContext();
export const useVariableDataStore = () => useContext(VariableDataStore);


export const initialState = {
  vsets: [],
  variables: []
};

export default function VariableDataStoreProvider({children}) {
  const [variableData, setVariableData] = useState(initialState);

  return (
    <VariableDataStore.Provider value={{ variableData, setVariableData }}>
      {children}
    </VariableDataStore.Provider>
  );
}