import { Container, Row } from "react-bootstrap";
import { useTopDataStore } from "./data/TopDataStoreProvider";
import VariablesList from "./asc.variables.list";

function OutletSwitch() {
  const { topData } = useTopDataStore();

  switch (topData.page) {         
    case 'variables-request':
    case 'variablesDash':
    case 'variables':
    case 'variables-all':
    case 'variables-category':
    default:
      return (<VariablesList />);
  }
}

export default function VariableOutletId(){
  return(
    <Container> 
      <Row>
        <h2 className="pageTitle mt-4">
          Variable Sets
        </h2>
        <OutletSwitch />           
      </Row>
    </Container>
  );
}