import './styles/rebrand.css';
import { useStudyDataStore } from './data/StudyDataStoreProvider';
import { useTopDataStore } from './data/TopDataStoreProvider';
import { useDocumentDataStore } from './data/DocumentDataStoreProvider';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Tabs, Tab, Spinner, Dropdown } from 'react-bootstrap';
import { callToBackend } from './functions/fetch';
import { useWorkspaceDataStore } from './data/WorkspaceDataStoreProvider';
import { Plus } from './components/Icons';
import CreateDocument from './components/CreateDocument';
import { docIdGet } from './functions/api-calls';
import { VariableValues } from './components/VariableValues';
import { useLoadDataStore } from './data/LoadDataStoreProvider';

function Sublist() {
    const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
    const { topData, setTopData } = useTopDataStore(); 
    const { studyData } = useStudyDataStore();
    const { documentData, setDocumentData } = useDocumentDataStore();
    const { loadData, setLoadData } = useLoadDataStore();
    const documents = studyData.documents;
    
    return (
        <Row>
            { /* <h3 className="subheading">{title}</h3> */ }
            <div className="d-flex flex-wrap gap-3 align-items-start">
                {documents.length === 0 ? (
                    <p>No documents</p>
                ) : (
                    documents.map((doc, index) => (
                        <div 
                            key={index} 
                            className="listBlockWrapper" 
                            style={{ width: '31%'}}
                        >
                            <h5>{doc.name}</h5>
                            <p>{doc.description}</p>
                            <p>{doc.type}</p>
                            <p>Version: {doc.version}</p>
                            {doc.templateId && <p>Based on: {doc.templateId}</p>}
                            <div className="d-flex justify-content-end">
                                <Dropdown>
                                    <Dropdown.Toggle variant="link" className="button-link">
                                        Actions
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => {
                                                setLoadData({ ...loadData, isLoading: true });
                                                docIdGet(topData, setTopData, documentData, setDocumentData, loadData, setLoadData, doc.id, doc.version);
                                            }}>
                                            Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item 
                                            onClick={() => 
                                                deleteDoc(doc.id, studyData, doc.version, topData, workspaceData, setWorkspaceData)
                                            }
                                        >
                                            Hard delete
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleCreateNewVersion(doc.id)}>
                                            Create new version
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </Row>
    );
}

function handleCreateNewVersion(studyId) {
    // Placeholder for PUT call to create a new version
    alert("Versioning is not yet available for study with ID: " + studyId);
}

async function deleteDoc(docId, studyData, docVersion, topData, workspaceData, setWorkspaceData) {
    const params = {
        email: topData.userEmail,
        groupName: topData.groupName,
        userId: topData.userId,
        removeDocuments: {
            id: docId,
            version: docVersion
        },
        id: studyData.id,
        version: studyData.version
    };

    const url = topData.endpoint + "/study";
    try {
        const response = await callToBackend(url, params, topData.token, {}, 'PUT');
        if (response.status === 200) {
            const data = await response.json();
            setWorkspaceData({
                ...workspaceData,
                ...data.workspace
            });
        } else {
            const errorData = await response.json();
            alert(errorData.message || "Error deleting document.");
        }
    } catch (err) {
        console.error(err);
        alert("Error deleting document.");
    }
}

function DocumentList() {
    const { workspaceData } = useWorkspaceDataStore();
    const { studyData, setStudyData } = useStudyDataStore();
    const { topData, setTopData } = useTopDataStore();
    const { documentData, setDocumentData } = useDocumentDataStore();
    const [showModal, setShowModal] = useState(false);
    const { loadData, setLoadData } = useLoadDataStore();
    const [newDocument, setNewDocument] = useState({
        name: '',
        description: '',
        type: '',
        templateVersion: '',
        templateId: ''
    });

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewDocument({
            ...newDocument,
            [name]: value
        });
    };

    const handleSave = async (formState) => {
        const url = topData.endpoint + "/document";
        const params = {
            email: topData.userEmail,
            groupName: topData.groupName,
            userId: topData.userId,
            name: formState.name,
            description: formState.description,
            type: formState.type,
            templateId: formState.templateId,
            templateVersion: formState.templateVersion,
            studyId: studyData.id,
            studyVersion: studyData.version
        };

        try {
            const response = await callToBackend(url, params, topData.token, {}, 'POST');
            if (response.status === 200) {
                const data = await response.json();
                setStudyData({
                    ...studyData,
                    ...data.study
                });
                setLoadData({
                    ...loadData,
                    isLoading: true,
                })
                docIdGet(topData, setTopData, documentData, setDocumentData, loadData, setLoadData, data.id, data.version);
                handleClose();
            } else {
                const errorData = await response.json();
                alert(errorData.message || "Error saving new draft.");
            }
        } catch (error) {
            console.error("Error saving document:", error);
            alert("Error saving new draft.");
        }
    };

    return (
        <Container>
            <Row>
                <div className="docListItem" id="newStudy"> {/* REVIEW CSS */}
                    <a href="#id" onClick={handleShow}>
                        <Plus />Add Document
                    </a>
                </div>
            </Row>
            <Sublist studies={workspaceData.studies} title="Studies" />

            <CreateDocument 
                show={showModal} 
                handleClose={handleClose} 
                newDocument={newDocument} 
                handleChange={handleChange} 
                handleSave={handleSave} 
                existing={workspaceData.activeTemplates}
            />
        </Container>
    );

}

function Metadata({dataStore, setDataStore, type}) {
    const [isSavingMetadata, setIsSavingMetadata] = useState(false);
    const [isEditingMetadata, setIsEditingMetadata] = useState(false);
    const [localMetadata, setLocalMetadata] = useState({});
    const {topData, setTopData} = useTopDataStore();

    useEffect(() => {
        setLocalMetadata({
            name: dataStore.name,
            description: dataStore.description,
            status: dataStore.status
        });
    }, [dataStore]);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setLocalMetadata(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleEditMetadata = () => {
        setIsEditingMetadata(true);
    };

    const handleCancelEdit = () => {
        setIsEditingMetadata(false);
        setLocalMetadata({
            name: dataStore.name,
            description: dataStore.description,
            status: dataStore.status
        });
    };

    const handleSave = async () => {
        setIsSavingMetadata(true);
        const params = {
            id: dataStore.id,
            oldVersion: dataStore.version,
            name: localMetadata.name,
            description: localMetadata.description,
            status: localMetadata.status,
            email: topData.userEmail,
            groupName: topData.groupName,
            userId: topData.userId
        };
        const url = type = "study" ? `${topData.endpoint}/study` : `${topData.endpoint}/document`;

        try {
            const res = await callToBackend(url, params, topData.token, {}, 'PUT');
            if (res.status === 200) {
                const data = await res.json();
                setTopData({
                    ...topData,
                    page: type = "study" ? "studyText" : "edit",
                });
                const objectOfInterest = type = "study" ? data.study : data.doc;
                setDataStore({
                    ...dataStore,
                    ...objectOfInterest,
                });
            } else {
                const errorData = await res.json();
                alert(errorData.message || `Error retrieving ${type}.`);
            }
        } catch (err) {
            console.error(err);
            alert(`Error retrieving ${type}.`);
        }
        setIsSavingMetadata(false);
        setIsEditingMetadata(false);
    };


    return(
            <div>
                    <Row>
                        <Col> 
                            <p className="text-muted text-start">{dataStore.parent?.id ? `Child of ${dataStore.parent?.id}` : "No parent."}</p>
                        </Col>
                    </Row>
                    <Form className="d-flex flex-column gap-1">
                        <Form.Group controlId="formName" className="d-flex flex-row gap-2 w-100 align-items-center">
                            <Form.Label className="label-style-light pb-0 mb-0 align-items-center" >Title:</Form.Label>
                            {isEditingMetadata ? (
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={localMetadata.name}
                                    onChange={handleFormChange}
                                    style={{ 
                                        backgroundColor: '#fdf6e3',
                                        color: '#333', 
                                        border: '1px solid #ddd' 
                                    }}
                                />
                            ) : (
                                dataStore.name
                            )}
                        </Form.Group>
                        <Form.Group controlId="formDescription" className="d-flex flex-row gap-2 w-100 align-items-center">
                            <Form.Label className="label-style-light pb-0 mb-0 align-items-center" >Description:</Form.Label>
                            {isEditingMetadata ? (
                                <Form.Control
                                    type="text"
                                    name="description"
                                    value={localMetadata.description}
                                    onChange={handleFormChange}
                                    style={{ 
                                        backgroundColor: '#fdf6e3',
                                        color: '#333', 
                                        border: '1px solid #ddd' 
                                    }}
                                />
                            ) : (
                                dataStore.description
                            )}
                        </Form.Group>
                        <Form.Group controlId="formStatus" className="d-flex flex-row gap-2 w-100 align-items-center">
                            <Form.Label className="label-style-light pb-0 mb-0 align-items-center" >Status:</Form.Label>
                            {isEditingMetadata ? (
                                <Form.Control
                                    as="select"
                                    name="status"
                                    value={localMetadata.status}
                                    onChange={handleFormChange}
                                    style={{ 
                                        backgroundColor: '#fdf6e3',
                                        color: '#333', 
                                        border: '1px solid #ddd' 
                                    }}
                                >
                                    <option value="draft">Draft</option>
                                    <option value="active">Active</option>
                                    <option value="archived">Archived</option>
                                </Form.Control>
                            ) : (
                                dataStore.status
                            )}
                        </Form.Group>
                        {isEditingMetadata ? (
                                <div className="d-flex flex-row gap-2 mt-3">
                                    <Button 
                                        variant="secondary" 
                                        onClick={handleCancelEdit}
                                        className="button-save-light"
                                    >
                                        Cancel
                                    </Button>
                                    <Button 
                                        variant="secondary" 
                                        onClick={handleSave} 
                                        className="button-save-dark"
                                        disabled={isSavingMetadata}
                                    >
                                        {isSavingMetadata ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="me-2"
                                                />
                                                Saving...
                                            </>
                                        ) : 'Save'}
                                    </Button>
                                </div>
                        ) : (
                                <div className="d-flex flex-row gap-2 mt-3">
                                    <Button 
                                        variant="secondary" 
                                        onClick={handleEditMetadata}
                                        className="button-save-light"
                                    >
                                        Edit
                                    </Button>
                                </div>
                        )}
                    </Form>
       </div>
    );
}

export default function StudyText() {
    const { studyData, setStudyData } = useStudyDataStore();

    const [activeTab, setActiveTab] = useState('metadata');

    const handleTabSelect = (key) => {
        if (key === 'contents') {
            alert("Feature coming soon.");
        } else if (key === 'save') {
            alert("For now, simple saves are happening every time you save form fields and text. Advanced saves, including versioning and amendments tables, is coming soon.");
        } else {
            setActiveTab(key);
        }
    };

    return (
        <Container>
            <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="mb-3">
                <Tab eventKey="metadata" title="Metadata">
                    <Metadata dataStore={studyData} setDataStore={setStudyData} type="study" />  
                </Tab>
                <Tab eventKey="values" title="Variable Values">
                    <VariableValues />  
                </Tab>
                <Tab eventKey="documents" title="Documents">
                    <DocumentList />
                </Tab>
            </Tabs>
        </Container>
    );
}
