import { createContext, useState, useContext } from "react";

// Create EvenState
const TopDataStore = createContext();
export const useTopDataStore = () => useContext(TopDataStore);

/*
  TopDataStore stores data about the display and user credentials. It may contain
  information about page loading and other warnings to be shared with the user. It does not
  contain any data about documents, templates, or variables.

  Until a router is implemented, topData stores the navigation data.
*/
/*
  API Gateway Endpoints: 
  https://n3i11u7ok1.execute-api.us-east-1.amazonaws.com/1 = production = app.asclepiagroup.com
  https://8wzmv0fmve.execute-api.us-east-1.amazonaws.com/0 = development = dev.asclepiagroup.com
  https://o4pvasobcc.execute-api.us-east-1.amazonaws.com/qa = qa = qa.asclepiagroup.com
  /TODO: Delete and archive: devin = personal development space https://sk1lbj1lpj.execute-api.us-east-1.amazonaws.com/alpha
  Note: deploy.sh automatically updates the endpoint to the proper environment
*/

export const initialState = {
  token : "", // User credentials (encrypted ? )
  userEmail : "", // User credentials (not encrypted)
  userPassword: "", // User credentials (encrypted ? )
  userId : "", // User credentials (encrypted ? )
  confirmCode : "", // User credential (encrypted ? )
  AccessKeyId : "", // User credentials (encrypted ? )
  SecretAccessKey : "", // User credentials (encrypted ? )
  nonce : "", // User credentials (encrypted ? )
  loginWarning : "", // User credential verified
  lockLogin : false, // User credentials verified
  lockedEmails : [],  // User credentials verified 

  groupName : "", // Workspace ? can this be used to define companies?
  endpoint : "https://8wzmv0fmve.execute-api.us-east-1.amazonaws.com/0", // Identifies the API for the environment
  page : "login", // Temporary strategy to track the page using switch statements until we implement a router

  viewHeight : window.outerHeight, // TO DO: Delete this? Window measurement
  loading : false,
  // Not certain what these do
  footer : false, 
  existing : [], 
};

export default function TopDataStoreProvider({children}) {
  const [topData, setTopData] = useState(initialState);
  
  return (
    <TopDataStore.Provider value={{ topData, setTopData }}>
      {children}
    </TopDataStore.Provider>
  );
} 
