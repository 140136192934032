import { createContext, useState, useContext } from "react";

// Create LoadDataStore
const LoadDataStore = createContext();
export const useLoadDataStore = () => useContext(LoadDataStore);

/*
  LoadDataStore is a data store provider with async / promise features
*/

export const initialLoadState = {
  loadingProgress: 0,
  isLoading: false,
};

export default function LoadDataStoreProvider({ children }) {
  const [loadData, setLoadDataState] = useState(initialLoadState);

  // Wrap setLoadDataState in a promise to make it awaitable
  const setLoadData = (newData) => {
    return new Promise((resolve) => {
      setLoadDataState((prevData) => {
        const updatedData = { ...prevData, ...newData };
        resolve(updatedData);
        return updatedData;
      });
    });
  };

  return (
    <LoadDataStore.Provider value={{ loadData, setLoadData }}>
      {children}
    </LoadDataStore.Provider>
  );
} 
