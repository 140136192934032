import React, { useState } from 'react';
import { Modal, Form, Button, Spinner } from 'react-bootstrap';

const CreateTemplate = ({ show, handleClose, handleChange, handleSave, activeTemplates }) => {
    const [formState, setFormState] = useState({
        name: '',
        parentId: '',
        parentVersion: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const resetForm = () => {
        setFormState({
            name: '',
            parentId: '',
            parentVersion: ''
        });
    };

    const handleCancel = () => {
        resetForm();
        handleClose();
    };

    const handleDropdownChange = (e) => {
        if (e.target.value === "") {
            setFormState({
                ...formState,
                parentId: '',
                parentVersion: ''
            });
        } else {
            const selectedTemplate = activeTemplates.find(template => template.id === e.target.value);
            if (selectedTemplate) {
                setFormState({
                    ...formState,
                    parentId: selectedTemplate.id,
                    parentVersion: selectedTemplate.version
                });
            } 
        }
    };

    const handleSaveClick = async () => {
        setIsLoading(true);
        try {
            await handleSave(formState);
        } catch (error) {
            console.error("Error saving template:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleCancel} className="dark-modal" backdropClassName="dark-backdrop">
            <Modal.Header closeButton={false} style={{ borderBottom: 'none' }}>
                <Modal.Title className="modal-form-title">Create New Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="d-flex flex-column gap-3">
                    <Form.Group controlId="formTemplateName" className="d-flex flex-column gap-0 align-items-start">
                        <Form.Label className="label-style-dark">Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formState.name}
                            onChange={(e) => {
                                setFormState({ ...formState, name: e.target.value });
                                handleChange(e); // Ensure handleChange is called
                            }}
                            className="form-control-dark"
                        />
                    </Form.Group>
                    <Form.Group controlId="formTemplateParent" className="d-flex flex-column gap-0 align-items-start">
                        <Form.Label className="label-style-dark">Parent Template Name</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={handleDropdownChange}
                            className="form-control-dark dropdown-style"
                        >
                            <option value="">No parent template</option>
                            {activeTemplates.map(template => (
                                <option key={template.id} value={template.id}>
                                    {template.name} ({template.version})
                                </option>
                            ))}
                        </Form.Control>
                        <input type="hidden" name="parentId" value={formState.parentId} />
                        <input type="hidden" name="parentVersion" value={formState.parentVersion} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleCancel} className="button-cancel-dark">Cancel</Button>
                <Button 
                    variant="secondary" 
                    onClick={handleSaveClick} 
                    className="button-save-dark"
                    disabled={isLoading}
                >
                    {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateTemplate;
