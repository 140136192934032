import './App.css';
import { Container, Row } from 'react-bootstrap';

import { useTopDataStore } from "./data/TopDataStoreProvider";
import { useDocumentDataStore } from "./data/DocumentDataStoreProvider";
import DocumentText from "./asc.doc.ID.edit.js";
import { useStudyDataStore } from './data/StudyDataStoreProvider.js';

// This sets the subpage within the Document module
function OutletSwitch() {
  // Temporary use of TopDataStoreProvider for page before adding router
  const { topData } = useTopDataStore();  
  switch (topData.page) {
    case "documentSetup":
    case "documentText":
    case "documentSaveChoices":
    default:
      return (<DocumentText />);
  }
}

export default function DocumentOutletID() {
  const { documentData } = useDocumentDataStore();
  const { studyData } = useStudyDataStore();
  const { topData, setTopData } = useTopDataStore();

  // Page layout
  return(
    <Container className="mainPage">
      <Row>
        <h2 className="pageTitle">
          {(documentData.name)}
        </h2>
        <nav className="kinetikaOrange breadcrumb ps-3">
          <span 
            className="breadcrumb-item" 
            onClick={() => setTopData({ ...topData, page: "studyList" })}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            Studies
          </span>
          <span 
            className="kinetikaOrange breadcrumb-item" 
            onClick={() => setTopData({ ...topData, page: "studyText" })}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            {studyData.name}
          </span>
          <span 
            className="breadcrumb-item"
            style={{ color: 'black' }}>
            {documentData.name}
          </span>
        </nav>
      </Row>
      <Row> 
        <OutletSwitch /> 
      </Row>
    </Container>
  );
}
