import './App.css';
import { Container, Row } from 'react-bootstrap';

import { useTopDataStore } from "./data/TopDataStoreProvider";
import { useTemplateDataStore } from "./data/TemplateDataStoreProvider";
import TemplateText from "./asc.template.ID.edit";

// This sets the subpage within the Template module
function OutletSwitch() {
  // Temporary use of TopDataStoreProvider for page before adding router
  const { topData } = useTopDataStore();  
  switch (topData.page) {
    case "templateSetup":
    case "templateText":
    case "templateSaveChoices":
    default:
      return (<TemplateText />);
  }
}

export default function TemplateOutletID() {
  const { templateData } = useTemplateDataStore();

  // Page layout
  return(
    <Container className="mainPage">
      <Row>
        <h2 className="pageTitle">
          {(templateData.name)}
        </h2>
      </Row>
      <Row> 
        <OutletSwitch /> 
      </Row>
    </Container>
  );
}
