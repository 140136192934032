import React, { useEffect, useState } from 'react';
import { Container, Row, Dropdown } from 'react-bootstrap';
import { Plus } from './components/Icons'; 
import { useWorkspaceDataStore } from './data/WorkspaceDataStoreProvider';
import { useTopDataStore } from './data/TopDataStoreProvider';
import { useLoadDataStore } from './data/LoadDataStoreProvider';
import CreateTemplate from './components/CreateTemplate'; 
import { deleteTemplate, createTemplate } from './functions/templates';
import { templateIdGet } from './functions/api-calls';
import { useTemplateDataStore } from './data/TemplateDataStoreProvider.js';
import { callToBackend } from './functions/fetch.js';

function Sublist({ templates, title }) {
    const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
    const { topData, setTopData } = useTopDataStore(); 
    const { templateData, setTemplateData } = useTemplateDataStore();
    return (
        <Row>
            <h3 className="subheading">{title}</h3>
            <div className="d-flex flex-wrap gap-3 align-items-start">
                {templates.length === 0 ? (
                    <p>No {title.toLowerCase()} templates</p>
                ) : (
                    templates.map((template, index) => (
                        <div 
                            key={index} 
                            className="listBlockWrapper" 
                            style={{ width: '31%'}}
                        >
                            <h5>{template.name}</h5>
                            <p>{template.description}</p>
                            <p>Version: {template.version}</p>
                            {template.parentTemplate && <p>Parent: {template.parentTemplate}</p>}
                            <div className="d-flex justify-content-end">
                                <Dropdown>
                                    <Dropdown.Toggle variant="link" className="button-link">
                                        Actions
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() =>
                                                templateIdGet(topData, setTopData, templateData, setTemplateData, template.id, template.version)
                                        }>
                                            Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item 
                                            onClick={() => 
                                                deleteTemplate(template.id, template.name, template.version, template.status, topData, workspaceData, setWorkspaceData)
                                            }
                                        >
                                            Hard delete
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleCreateNewVersion(template.id)}>
                                            Create new version
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </Row>
    );
}

function handleCreateNewVersion(templateId) {
    // Placeholder for PUT call to create a new version
    alert("Versioning is not yet available for study with ID: " + templateId);
}

export default function TemplateList() {
    const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
    const { topData, setTopData } = useTopDataStore();
    const { setLoadData } = useLoadDataStore();
    const { templateData, setTemplateData } = useTemplateDataStore();
    const [showModal, setShowModal] = useState(false);
    const [newTemplate, setNewTemplate] = useState({
        name: '',
        description: '',
        parentVersion: '',
        parentId: ''
    });

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewTemplate({
            ...newTemplate,
            [name]: value
        });
    };

    const handleSave = (formState) => {
        const details = {
            name: formState.name,
            parentVersion: formState.parentVersion,
            parentId: formState.parentId
        };

        createTemplate(details, topData, setTopData, templateData, setTemplateData)
            .then(response => {
                if (response.status === 200) {
                    handleClose();
                } else {
                    alert(`Error: ${response.statusText}`);
                }
            })
            .then({
            })
            .catch(error => {
                alert(`Error: ${error.message}`);
            });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = {
                    email: topData.userEmail,
                    userId: topData.userId,
                    groupName: topData.groupName,
                };
                const url = `${topData.endpoint}/template`;
                const res = await callToBackend(url, params, topData.Authorization, {}, 'GET');
                
                if (res.status === 401) {
                    const data = await res.json();
                    alert(data.message || "Error retrieving workspace templates.");
                } else if (res.status === 200) {
                    const data = await res.json();
                    setWorkspaceData({
                        ...workspaceData,
                        activeTemplates: data.active,
                        draftTemplates: data.drafts,
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []); // Run once on load

    return (
        <Container>
            <Row>
                <h2 className="pageTitle mt-4">Templates</h2>
            </Row>
            <Row>
                <div className="docListItem" id="newTemplate"> {/* REVIEW CSS */}
                    <a href="#id" onClick={handleShow}>
                        <Plus />Create New Template
                    </a>
                </div>
            </Row>
            <Sublist templates={workspaceData.draftTemplates} title="Drafts" />
            <Sublist templates={workspaceData.activeTemplates} title="Active" />

            <CreateTemplate 
                show={showModal} 
                handleClose={handleClose} 
                newTemplate={newTemplate} 
                handleChange={handleChange} 
                handleSave={handleSave} 
                activeTemplates={workspaceData.draftTemplates}
            />
        </Container>
    );
}
