import { callToBackend } from "./fetch";
import Cookies from 'js-cookie';
/**
 * Fetches data for the workspace based on the specified types in getData.
 * 
 * @param {Object} topData - Contains user and session information.
 * @param {Function} setTopData - Function to update top-level data.
 * @param {Object} workspaceData - Current workspace data.
 * @param {Function} setWorkspaceData - Function to update workspace data.
 * @param {Function} setLoadData - Function to update loading state.
 * @param {Array} getData - Array of strings indicating data types to fetch ("V", "S", "T").
 */
export async function fetchDataForWorkspace(topData, workspaceData, setWorkspaceData, setLoadData, getData = []) {
  const getVars = getData.includes("V");
  const getStudies = getData.includes("S"); // To do: Update this to S for studies
  const getTemps = getData.includes("T");

  // Initialize loading state
  await setLoadData({ isLoading: true, loadingProgress: 0 });

  // Prepare parameters for API calls
  const params = {
    email: topData.userEmail,
    userId: topData.userId,
    groupName: topData.groupName,
  };

  // Temporary storage for fetched data
  let vData = {};
  let sData = {};
  let tData = {};

  try {
    // Fetch variables if requested
    if (getVars) {
      const url = `${topData.endpoint}/defaults`;
      const res = await callToBackend(url, params, topData.token, {}, 'GET', false);
      if (res.status === 401) {
        const data = await res.json();
        alert(data.message || "Error retrieving workspace variables.");
      } else if (res.status === 200) {
        const data = await res.json();
        vData = {
          varSets: data.var_sets,
        };
      }
      // Update loading progress
      const newProgress = 100 / getData.length;
      await setLoadData({ loadingProgress: newProgress });
    }

    // Fetch documents if requested
    if (getStudies) {
      const url = `${topData.endpoint}/study`;
      const res = await callToBackend(url, params, topData.token, {}, 'GET', false);
      if (res.status === 401) {
        const data = await res.json();
        alert(data.message || "Error retrieving workspace studies.");
      } else if (res.status === 200) {
        const data = await res.json();

        sData = {
          studies: data.studies,
        };
      }
      // Update loading progress
      const newProgress = 100 / getData.length;
      await setLoadData({ loadingProgress: newProgress });
    }

    // Fetch templates if requested
    if (getTemps) {
      const url = `${topData.endpoint}/template`;
      const res = await callToBackend(url, params, topData.Authorization, {}, 'GET');
      if (res.status === 401) {
        const data = await res.json();
        alert(data.message || "Error retrieving workspace templates.");
      } else if (res.status === 200) {
        const data = await res.json();
        tData = {
          activeTemplates: data.active,
          draftTemplates: data.drafts,
        };
      }
      // Update loading progress
      const newProgress = 100 / getData.length;
      await setLoadData({ loadingProgress: newProgress });
    }

    // Update workspace data with fetched results
    setWorkspaceData({
      ...workspaceData,
      ...vData,
      ...sData,
      ...tData,
    });
  } finally {
    // Reset loading state to indicate completion
    await setLoadData({ isLoading: false, loadingProgress: 100 });
  }
}

export function logout(setWorkspaceData, refreshState, setTopData, initialState) {
  Cookies.remove('user', { path: '/', secure: true, sameSite: 'strict'  }); // Include the values used when setting a cookie to delete
  Cookies.remove('cc_cookie', { path: '/', sameSite: 'lax' }); // https://github.com/js-cookie/js-cookie
  setWorkspaceData(refreshState);
  setTopData(initialState);
  // Reload page to retrigger cookie consent.
  window.location.reload(true);
}