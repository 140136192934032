import React, { useState } from 'react';
import { Modal, Form, Button, Spinner } from 'react-bootstrap';

// This can be merged with CreateTemplate easily when time permits.

const CreateStudy = ({ show, handleClose, handleChange, handleSave, existingStudies = [] }) => {
    const [formState, setFormState] = useState({
        name: '',
        description: '',
        cloneId: '',
        cloneVersion: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const resetForm = () => {
        setFormState({
            name: '',
            description: '',
            cloneId: '',
            cloneVersion: ''
        });
    };

    const handleCancel = () => {
        resetForm();
        handleClose();
    };

    const handleDropdownChange = (e) => {
        if (e.target.value === "") {
            setFormState({
                ...formState,
                cloneId: '',
                cloneVersion: ''
            });
        } else {
            const selectedStudy = existingStudies.find(study => study.id === e.target.value);
            if (selectedStudy) {
                setFormState({
                    ...formState,
                    cloneId: selectedStudy.id,
                    cloneVersion: selectedStudy.version
                });
            } 
        }
    };

    const handleSaveClick = async () => {
        setIsLoading(true);
        try {
            await handleSave(formState);
        } catch (error) {
            console.error("Error saving study:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleCancel} className="dark-modal" backdropClassName="dark-backdrop">
            <Modal.Header closeButton={false} style={{ borderBottom: 'none' }}>
                <Modal.Title className="modal-form-title">Create New Study</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="d-flex flex-column gap-3">
                    <Form.Group controlId="formStudyName" className="d-flex flex-column gap-0 align-items-start">
                        <Form.Label className="label-style-dark">Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formState.name}
                            onChange={(e) => {
                                setFormState({ ...formState, name: e.target.value });
                                handleChange(e); // Ensure handleChange is called
                            }}
                            className="form-control-dark"
                        />
                    </Form.Group>
                    <Form.Group controlId="formStudyParent" className="d-flex flex-column gap-0 align-items-start">
                        <Form.Label className="label-style-dark">Clone another study?</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={handleDropdownChange}
                            className="form-control-dark dropdown-style"
                        >
                            <option value="">Do not clone</option>
                            {existingStudies.map(study => (
                                <option key={study.id} value={study.id}>
                                    {study.name} ({study.version})
                                </option>
                            ))}
                        </Form.Control>
                        <input type="hidden" name="cloneId" value={formState.cloneId} />
                        <input type="hidden" name="cloneVersion" value={formState.cloneVersion} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleCancel} className="button-cancel-dark">Cancel</Button>
                <Button 
                    variant="secondary" 
                    onClick={handleSaveClick} 
                    className="button-save-dark"
                    disabled={isLoading}
                >
                    {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateStudy;
