/*
  TO DO: Investigate best practices around Data Stores. 
  I think this is another case where this is too specific and
  we don't need to give it it's own -- 
  Another consideration is that this is only accessed on the 
  Dashboard so why wouldn't we make a local call?
  Does it have to do with how the state and context are updated?
*/

import { createContext, useState, useContext } from "react";

const NotificationsDataStore = createContext();
export const useNotificationsDataStore = () => useContext(NotificationsDataStore);

export default function NotificationsDataStoreProvider({children}) {
  const [notificationData, setNotificationData] = useState({notifications: []});
  
  return (
    <NotificationsDataStore.Provider value={{ notificationData, setNotificationData }}>
      {children}
    </NotificationsDataStore.Provider>
  );
}