export async function callToBackend(url, params, token, additionalHeaders, method, workAround=false, additionalBody={}) {
    // Updated logging to print each key/value pair on its own line
    const logParams = (obj, prefix = '') => {
        for (const [key, value] of Object.entries(obj)) {
            if (typeof value === 'object' && value !== null) {
                logParams(value, `${prefix}${key}.`);
            }  
        }
    };
    
    logParams(params);
    const customEncodeURIComponent = (str) => {
        return encodeURIComponent(str)
            .replace(/%40/g, '@')
            .replace(/%3D/g, '=');
    };
    let paramsString = '';
    if (!workAround) {
        paramsString = Object.entries(params)
            .map(([key, value]) => `${customEncodeURIComponent(key)}=${customEncodeURIComponent(value)}`)
            .join('&');
    } else {
        // Create a comma-separated string of values from params
        paramsString = "p=" + Object.values(params).join(',');
    }
    const headers = {
        Authorization: token,
        ...additionalHeaders
    };

    if (method === 'GET') {
        const response = await fetch(`${url}?${paramsString}`, {
            method: 'GET',
            headers: headers,
            cache: 'no-cache'
        });
        return response;
    }

    if (method === 'DELETE') {
        const response = await fetch(url, {
            method: 'DELETE',
            body: JSON.stringify(params),
            headers: headers,
        });
        return response;
    }

    if(method === 'PUT') {
        // FIND AN EXAMPLE!
        const response = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(params),
            headers: headers,
        })
        return response;
    }

    if(method === 'POST') {
        // FIND AN EXAMPLE!
        const response = await fetch(url, {
            method: 'POST',
            body: (Object.values(params).length > 0) ? JSON.stringify(params) : additionalBody,
            headers: headers,
        })
        return response;
    }
}