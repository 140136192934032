import { useStudyDataStore } from "../data/StudyDataStoreProvider";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { useState } from "react";
import { useWorkspaceDataStore } from "../data/WorkspaceDataStoreProvider";
import { useTopDataStore } from "../data/TopDataStoreProvider";
import { callToBackend } from "../functions/fetch";

export function VariableValues() {
    const { studyData, setStudyData } = useStudyDataStore();
    const { workspaceData } = useWorkspaceDataStore();
    const { topData } = useTopDataStore();

    console.log("study data", studyData);

    const studyVarsetIds = new Set();
    const [isEditing, setIsEditing] = useState(false);
    const missingPlaceholders = [];

    const [studyVariables, setStudyVariables] = useState(
        Object.keys(studyData.values).reduce((acc, key) => {
            let transformedKey = key;
            if (/\.$|comma$|bullet$|[0123456789]\.$/.test(key)) {
                transformedKey = key.replace(/\.\d*\.$/, '');
            }
            const matchingVarSet = workspaceData.varSets.find(varSet =>
                varSet.variables.some(variable =>
                    variable.placeholder.replace(/^#/, '') === transformedKey
                )
            );

            if (matchingVarSet) {
                acc[transformedKey] = {
                    values: studyData.values[key],
                    originalKey: key,
                    updated: false,
                    varSet: matchingVarSet.id
                };
                studyVarsetIds.add(matchingVarSet.id);
            } else {
                acc[transformedKey] = {
                    values: studyData.values[key],
                    originalKey: key,
                    updated: false
                };
                missingPlaceholders.push(key);
            }
            return acc;
        }, {})
    );

    const studyVarsets = Array.from(studyVarsetIds);

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleFormChange = (key, value) => {
        setStudyVariables(prev => ({
            ...prev,
            [key]: {
                ...prev[key],
                values: [value],
                updated: true
            }
        }));
    };

    const handleSave = async () => {
        const updatedVariables = Object.entries(studyVariables)
            .filter(([_, variable]) => variable.updated)
            .reduce((acc, [key, variable]) => {
                acc[key] = variable.values;
                if (key !== variable.originalKey) {
                    acc[variable.originalKey] = variable.values;
                }
                return acc;
            }, {});

        console.log(updatedVariables);
        const params = {
            id: studyData.id,
            oldVersion: studyData.version,
            updateValues: updatedVariables,
            email: topData.userEmail,
            groupName: topData.groupName,
            userId: topData.userId
        };
        const url = `${topData.endpoint}/study`;

        try {
            const res = await callToBackend(url, params, topData.token, {}, 'PUT');
            if (res.status === 200) {
                const data = await res.json();
                setStudyData({
                    ...studyData,
                    ...data.study,
                });
                console.log("returned", studyData);
            } else {
                const errorData = await res.json();
                alert(errorData.message || `Error updating variables.`);
            }
        } catch (err) {
            console.error(err);
            alert(`Error updating variables.`);
        }
        setIsEditing(false);
    };

    const handleCancel = () => {
        console.log("Here we would revert to the original study");
        setIsEditing(false);
    };

    return (
        <Container>
            {missingPlaceholders.length > 0 && (
                <p className="text-muted">
                    No global variable found for: {missingPlaceholders.join(', ')}
                </p>
            )}
            <div className="d-flex flex-row gap-2 mt-3 w-auto mb-2">
                {isEditing ? (
                    <>
                        <Button variant="secondary" onClick={handleCancel} className="button-save-light">
                            Cancel
                        </Button>
                        <Button variant="secondary" onClick={handleSave} className="button-save-dark">
                            Save
                        </Button>
                    </>
                ) : (
                    <Button variant="secondary" onClick={handleEditToggle} className="button-save-light">
                        Edit
                    </Button>
                )}
            </div>
            <div>
                {
                    studyData.documents.length < 1 ?
                        <p>Add a document to see variables.</p> :
                        Object.keys(studyData.values).length < 1 ?
                            <p>Documents do not contain variables.</p> :
                            workspaceData.varSets
                                .filter(varSet => studyVarsets.includes(varSet.id))
                                .map((varSet, index) => {
                                    const matchingVariables = varSet.variables.filter(variable =>
                                        studyVariables.hasOwnProperty(variable.placeholder.replace(/^#/, ''))
                                    );

                                    return matchingVariables.length > 0 ? (
                                        <div key={index}>
                                            <h2 className="form-heading mt-4">{varSet.name}</h2>
                                            {matchingVariables.map((variable, varIndex) => {
                                                const key = variable.placeholder.replace(/^#/, '');
                                                const studyVariable = studyVariables[key];
                                                const value = studyVariable ? (studyVariable.values[0] ?  studyVariable.values[0] : "Not yet defined") : "Not yet defined";

                                                return (
                                                    <div key={varIndex} className="d-flex flex-column gap-2 mb-1">
                                                        <div className="d-flex flex-row gap-2 m-0 p-0">
                                                            <p className="label-style-light pb-0 mb-0 align-items-center">{variable.variant} ({variable.placeholder}): </p>
                                                            {isEditing ? (
                                                                variable.select ? (
                                                                    <Form.Group>
                                                                        <Form.Control
                                                                            as="select"
                                                                            value={value}
                                                                            style={{ 
                                                                                backgroundColor: '#fdf6e3',
                                                                                color: '#333', 
                                                                                border: '1px solid #ddd' 
                                                                            }}
                                                                            onChange={(e) => handleFormChange(key, e.target.value)}
                                                                        >
                                                                            {variable.options.map(option => (
                                                                                <option key={option} value={option}>{option}</option>
                                                                            ))}
                                                                        </Form.Control>
                                                                    </Form.Group>
                                                                ) : (
                                                                    <Form.Group>
                                                                        <Form.Control
                                                                            type="text"
                                                                            value={value}
                                                                            style={{ 
                                                                                backgroundColor: '#fdf6e3',
                                                                                color: '#333', 
                                                                                border: '1px solid #ddd' 
                                                                            }}
                                                                            onChange={(e) => handleFormChange(key, e.target.value)}
                                                                        />
                                                                    </Form.Group>
                                                                )
                                                            ) : (
                                                                <p className="pb-0 mb-0 align-items-centers">{value}</p>
                                                            )}
                                                        </div>
                                                        {
                                                            variable.example ? 
                                                                <div className="text-muted mt-0 mb-0" style={{ fontSize: '90%' }}>
                                                                    <strong>Guidance:</strong> ({variable.example})
                                                                </div>
                                                                : ""
                                                        }
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : null;
                                })
                }
            </div>
        </Container>
    );
}

export default VariableValues;