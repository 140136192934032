import { createContext, useState, useContext } from "react";

/*
  Template Data Store holds data about a template currently edited or being accessed
  for use in setting up a document. It does not contain any variable data.
  The list of templates accessible in the workspace is stored in the Workspace Data.
*/
const TemplateDataStore = createContext();
export const useTemplateDataStore = () => useContext(TemplateDataStore);

export const emptyState = {
  id: "",
  version: 1,
  name: "",
  description: "", 
  parent: {
    id: "",
    version: 1
  },
  variables: [],
  sections: []

};

export default function TemplateDataStoreProvider({children}) {
  const [templateData, setTemplateData] = useState(emptyState);
  
  return (
    <TemplateDataStore.Provider value={{templateData, setTemplateData}}>
      {children}
    </TemplateDataStore.Provider>
  );  
}